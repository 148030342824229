import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import HttpService from '../../services/http.service';

const defaultFragenState = {
    Firstname: '',
    Name: '',
    Email: '',
    Number: '',
    Description: '',
    Message: ''
};

const defaultFeedbackState = {
    Firstname: '',
    Name: '',
    Contact: '',
    Number: '',
    Description: '',
    Message: ''
};

const defaultKummerboxState = {
    Name: '',
    Contact: '',
    Event: '',
    Number: '',
    Message: ''
};

const requiredFields = {
    Fragen: ["Name", "Firstname", "Email", "Number", "Message"],
    Feedback: ["Number", "Message"],
    Kummerbox: ["Name", "Contact", "Event", "Message"]
};

export class Feedback extends React.Component {
    httpService;

    constructor(props) {
        super(props);

        this.state = {
            type: "",
            Fragen: defaultFragenState,
            Feedback: defaultFeedbackState,
            Kummerbox: defaultKummerboxState,
            formErrors: {},
            privacyChecked: false,
            showFormValidationErrorMessage: false,
            saving: false,
         };

        this.httpService = new HttpService();
        this.handleChange = this.handleChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(ev, key) {
        const target = ev.target;
        if (key) {
            const nextErrors = { ...this.state.formErrors };
            if (target.value && this.state.formErrors[target.name]) {
                delete nextErrors[target.name];
            }
            this.setState({ 
                ...this.state,
                formErrors: nextErrors,
                [key]: {
                    ...this.state[key],
                    [target.name]: target.value 
                }
            });
        } else {
            this.setState({ [target.name]: target.value });
        }
    }

    handleTypeChange(ev) {
        const target = ev.target;
        this.setState({
            type: target.value,
            formErrors: {},
            Fragen: defaultFragenState,
            Feedback: defaultFeedbackState,
            Kummerbox: defaultKummerboxState,
            privacyChecked: false
        });
    }

    handleCheckbox(ev) {
        const target = ev.target;
        const nextErrors = { ...this.state.formErrors };
        if (target.checked && this.state.formErrors[target.name]) {
            delete nextErrors[target.name];
        }
        this.setState({ [target.name]: target.checked, formErrors: nextErrors });
    }

    sendFrage() {
        this.setState({ saving: true });
        this.httpService.sendQuestion(this.state.Fragen)
            .then(() => {
                alert("Vielen Dank für deine Nachricht, die an die zuständige Lehrgangsleitung weitergeleitet wird. Ggf. wird sich die Lehrgangsleitung bei dir noch einmal melden.");
                this.setState({
                    type: "",
                    Fragen: defaultFragenState,
                    privacyChecked: false
                })
            })
            .catch((e) => {
                alert("Beim Versenden der Email ist ein Fehler passiert, bitte versuche es noch einmal.")
            })
            .finally(() => {
                this.setState({ saving: false });
            });
    }

    sendFeedback() {
        this.setState({ saving: true });
        this.httpService.sendFeedback(this.state.Feedback)
            .then(() => {
                alert("Vielen Dank für deine Nachricht, die an die zuständige Lehrgangsleitung weitergeleitet wird. Ggf. wird sich die Lehrgangsleitung bei dir noch einmal melden.");
                this.setState({
                    type: "",
                    Feedback: defaultFeedbackState,
                    privacyChecked: false
                })
            })
            .catch((e) => {
                alert("Beim Versenden der Email ist ein Fehler passiert, bitte versuche es noch einmal.")
            })
            .finally(() => {
                this.setState({ saving: false });
            });
    }

    sendKummerbox() {
        this.setState({ saving: true });
        this.httpService.sendKummerbox(this.state.Kummerbox)
            .then(() => {
                alert("Vielen Dank. Wir nehmen dein Anliegen sehr ernst und werden uns in Kürze mit dir in Verbindung setzen. ");
                this.setState({
                    type: "",
                    Kummerbox: defaultKummerboxState,
                    privacyChecked: false
                })
            })
            .catch((e) => {
                alert("Beim Versenden der Email ist ein Fehler passiert, bitte versuche es noch einmal.")
            })
            .finally(() => {
                this.setState({ saving: false });
            });
    }

    async handleSubmit() {
        if (!this.hasErrors()) {
            if (this.state[this.state.type].Number) {
                try {
                    await this.httpService.checkCourseNumber(this.state[this.state.type].Number);
                    if (this.state.type === 'Fragen') {
                        this.sendFrage();
                        return;
                    }
                    if (this.state.type === 'Feedback') {
                        this.sendFeedback();
                        return;
                    }
                    if (this.state.type === 'Kummerbox') {
                        this.sendKummerbox();
                        return;
                    }
                } catch {
                    alert("Der angegebene Lehrgang existiert nicht.")
                }
            } else {
                this.sendKummerbox();
            }
        }
    }

    validateRequiredFields(requiredFields, data) {
        return requiredFields.reduce((errors, field) => {
          if (!data[field]) {
            return { ...errors, [field]: 'Pflichtfeld' };
          }
          return errors;
        }, {});
    };

    hasErrors() {
        const errors = this.validateRequiredFields(requiredFields[this.state.type], this.state[this.state.type]);

        if (this.state[this.state.type].Number && this.state[this.state.type].Number.length !== 6) {
            errors.Number = 'Die Kursnummer muss aus 6 Zeichen bestehen';
        }

        if (this.state[this.state.type].Number && this.state[this.state.type].Number.length === 6 && !['L','B','A','M'].includes(this.state[this.state.type].Number[0])) {
            errors.Number = 'Die muss mit dem Buchstaben L, B, A oder M beginnen';
        }

        if (!this.state.privacyChecked) {
            errors.privacyChecked = 'Bitte die Datenschutzerklärung akzeptieren.';
        }
        this.setState({ formErrors: errors });
        return !!Object.keys(errors).length;
    }

    render() {
        return (
            <div className="boxed">
                <div className="mt-x-large mb-x-large">

                    <div className="row row-find-headline mb-medium">
                        <div className="col align-center">
                            <h1>Briefkasten</h1>
                            <p>Du möchtest eine Frage, ein Anliegen oder auch ein Feedback an die Lehrgangsleitung eines bestimmten Kurses schicken? Du benötigtest dafür nur die Lehrgangsnummer, die du z.B. auf deiner Anmeldebestätigung vom Kurs-Finder findest. Deine Anfrage / Feedback wird automatisch an die jeweils zuständige Lehrgangsleitung per Email versandt.</p>
                            <h1>Kummerbox</h1>
                            <p>Du kannst uns aber auch schreiben, wenn du dich bei einem Kursangebot unwohl gefühlt hast oder dir etwas aufgefallen ist, wo eine „Grenze“ von dir aber auch von anderen Teilnehmenden überschritten wurde, z.B. Mobbing, sexueller Übergriff, Alkoholmissbrauch usw.</p>
                            <p>Deine Nachricht geht an unsere Vertrauensperson, die für alle Blasmusikverbände in Bayern übergreifend zuständig ist. <a style={{ textDecoration: 'underline'}} href="https://www.blaeserjugend.de/themen/praevention-und-kinderschutz/ansprechpersonen.html" target="_blank">Hier</a> kannst du mehr über sie erfahren. Im persönlichen Kontakt schaut sie dein Anliegen genau an und bespricht mit dir jeden weiteren Schritt. Es passiert also nichts ohne deine Zustimmung und mit vorheriger Rücksprache. Hierfür ist es jedoch erforderlich, dass du uns eine Email- oder Telefonnummer von dir nennst. Bitte habe Verständnis, dass wir anonyme Nachrichten oder Nachrichten mit falschen Kontaktdaten nicht bearbeiten werden. Wenn du sofort mit jemanden sprechen möchtest oder anonym bleiben willst, wende dich bitte an die kostenlose Telefonseelsorge – die ist rund um die Uhr zu erreichen (Telefon 0800 / 111 0111 oder 0800 / 111 0222) oder an folgendes Online-Portal <a  style={{ textDecoration: 'underline'}} href="https://www.hilfe-portal-missbrauch.de" target="_blank">https://www.hilfe-portal-missbrauch.de</a></p>

                            
                        </div>
                    </div>

                    <div id="feedback-form" className="form-row row-insertion-form mb-x-large">
                        <div className="col-md-12">
                            <div className="form-group">
                                <select 
                                    className="form-control"
                                    name="type"
                                    placeholder="Wähle dein Anliegen aus"
                                    onChange={ this.handleTypeChange }
                                    value={ this.state.type } 
                                >
                                    <option value="">Wähle dein Anliegen aus</option>
                                    <option value="Fragen">Fragen / Anliegen / Wünsche zu einem Kursangebot</option>
                                    <option value="Feedback">Feedback zu einem Kursangebot</option>
                                    <option value="Kummerbox">Kummerbox</option>
                                </select>
                            </div>
                        </div>

                        {/* Fragen / Anliegen / Wünsche zu einem Kursangebot */}
                        {this.state.type === "Fragen" &&
                        <>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Vorname *"
                                        name="Firstname"
                                        value={this.state.Fragen.Firstname} 
                                        onChange={(e) => this.handleChange(e, 'Fragen')}
                                    />
                                    {this.state.formErrors.Firstname &&
                                    <span className='formError'>{this.state.formErrors.Firstname}</span>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Name *"
                                        name="Name"
                                        value={this.state.Fragen.Name} 
                                        onChange={(e) => this.handleChange(e, 'Fragen')}
                                    />
                                    {this.state.formErrors.Name &&
                                    <span className='formError'>{this.state.formErrors.Name}</span>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input 
                                        type="text"
                                        className="form-control" 
                                        placeholder="Email-Adresse *" 
                                        name="Email"
                                        value={this.state.Fragen.Email} 
                                        onChange={(e) => this.handleChange(e, 'Fragen')}
                                    />
                                    {this.state.formErrors.Email &&
                                    <span className='formError'>{this.state.formErrors.Email}</span>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input 
                                        type="text"
                                        className="form-control" 
                                        placeholder="Kursnummer (z.B. L11004)*" 
                                        name="Number"
                                        value={this.state.Fragen.Number} 
                                        onChange={(e) => this.handleChange(e, 'Fragen')}
                                    />
                                    {this.state.formErrors.Number &&
                                    <span className='formError'>{this.state.formErrors.Number}</span>
                                    }
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <textarea 
                                        style={{ height: '80px' }}
                                        className="form-control" 
                                        placeholder="Kurze Beschreibung des Kurses" 
                                        name="Description"
                                        value={this.state.Fragen.Description} 
                                        onChange={(e) => this.handleChange(e, 'Fragen')}
                                    />
                                    {this.state.formErrors.Description &&
                                    <span className='formError'>{this.state.formErrors.Description}</span>
                                    }
                                </div>
                            </div>
                            <div className="col-md-12 mb-medium">
                                <div className="form-group">
                                    <textarea 
                                        style={{ height: '150px' }}
                                        className="form-control" 
                                        placeholder="Fragen / Anliegen / Wünsche *" 
                                        name="Message"
                                        value={this.state.Fragen.Message}
                                        onChange={(e) => this.handleChange(e, 'Fragen')}
                                    />
                                    {this.state.formErrors.Message &&
                                    <span className='formError'>{this.state.formErrors.Message}</span>
                                    }
                                </div>
                            </div>
                        </>
                        }

                        {/* Feedback zu einem Kursangebot */}
                        {this.state.type === "Feedback" &&
                        <>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <input 
                                        type="text"
                                        className="form-control" 
                                        placeholder="Kursnummer (z.B. L11004)*" 
                                        name="Number"
                                        value={this.state.Feedback.Number}
                                        onChange={(e) => this.handleChange(e, 'Feedback')}
                                    />
                                    {this.state.formErrors.Number &&
                                    <span className='formError'>{this.state.formErrors.Number}</span>
                                    }
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <textarea 
                                        style={{ height: '80px' }}
                                        className="form-control" 
                                        placeholder="Kurze Beschreibung des Kurses" 
                                        name="Description"
                                        value={this.state.Feedback.Description}
                                        onChange={(e) => this.handleChange(e, 'Feedback')}
                                    />
                                    {this.state.formErrors.Description &&
                                    <span className='formError'>{this.state.formErrors.Description}</span>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Vorname"
                                        name="Firstname"
                                        value={this.state.Feedback.Firstname}
                                        onChange={(e) => this.handleChange(e, 'Feedback')}
                                    />
                                    {this.state.formErrors.Firstname &&
                                    <span className='formError'>{this.state.formErrors.Firstname}</span>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Name"
                                        name="Name"
                                        value={this.state.Feedback.Name}
                                        onChange={(e) => this.handleChange(e, 'Feedback')}
                                    />
                                    {this.state.formErrors.Name &&
                                    <span className='formError'>{this.state.formErrors.Name}</span>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input 
                                        type="text"
                                        className="form-control" 
                                        placeholder="Email-Adresse / Telefonnummer" 
                                        name="Contact"
                                        value={this.state.Feedback.Contact}
                                        onChange={(e) => this.handleChange(e, 'Feedback')}
                                    />
                                    {this.state.formErrors.Contact &&
                                    <span className='formError'>{this.state.formErrors.Contact}</span>
                                    }
                                </div>
                            </div>
                            <div className="col-md-12 mb-medium">
                                <div className="form-group">
                                    <textarea 
                                        style={{ height: '150px' }}
                                        className="form-control" 
                                        placeholder="Feedback / Rückmeldung *" 
                                        name="Message"
                                        value={this.state.Feedback.Message}
                                        onChange={(e) => this.handleChange(e, 'Feedback')}
                                    />
                                    {this.state.formErrors.Message &&
                                    <span className='formError'>{this.state.formErrors.Message}</span>
                                    }
                                </div>
                            </div>
                        </>
                        }

                        {/* Kummerbox */}
                        {this.state.type === "Kummerbox" &&
                        <>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Wie dürfen wir dich nennen? *"
                                        name="Name"
                                        value={this.state.Kummerbox.Name}
                                        onChange={(e) => this.handleChange(e, 'Kummerbox')}
                                    />
                                    {this.state.formErrors.Name &&
                                    <span className='formError'>{this.state.formErrors.Name}</span>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input 
                                        type="text"
                                        className="form-control hide-mobile" 
                                        placeholder="Wie können wir dich erreichen (Telefon oder Email)? *" 
                                        name="Contact"
                                        value={this.state.Kummerbox.Contact}
                                        onChange={(e) => this.handleChange(e, 'Kummerbox')}
                                    />
                                    <textarea 
                                        style={{ height: '80px' }}
                                        className="form-control show-mobile" 
                                        placeholder="Wie können wir dich erreichen (Telefon oder Email)? *" 
                                        name="Contact"
                                        value={this.state.Kummerbox.Contact}
                                        onChange={(e) => this.handleChange(e, 'Kummerbox')}
                                    />
                                    {this.state.formErrors.Contact &&
                                    <span className='formError'>{this.state.formErrors.Contact}</span>
                                    }
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <input 
                                        type="text"
                                        className="form-control hide-mobile" 
                                        placeholder="Um welchen Kurs bzw. welche Veranstaltung geht es? *" 
                                        name="Event"
                                        value={this.state.Kummerbox.Event}
                                        onChange={(e) => this.handleChange(e, 'Kummerbox')}
                                    />
                                    <textarea 
                                        style={{ height: '80px' }}
                                        className="form-control show-mobile" 
                                        placeholder="Um welchen Kurs bzw. welche Veranstaltung geht es? *" 
                                        name="Event"
                                        value={this.state.Kummerbox.Event}
                                        onChange={(e) => this.handleChange(e, 'Kummerbox')}
                                    />
                                    {this.state.formErrors.Event &&
                                    <span className='formError'>{this.state.formErrors.Event}</span>
                                    }
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <input 
                                        type="text"
                                        className="form-control hide-mobile" 
                                        placeholder="Weißt du evtl. auch die Kursnummer?" 
                                        name="Number"
                                        value={this.state.Kummerbox.Number}
                                        onChange={(e) => this.handleChange(e, 'Kummerbox')}
                                    />
                                    <textarea 
                                        style={{ height: '80px' }}
                                        className="form-control show-mobile" 
                                        placeholder="Weißt du evtl. auch die Kursnummer?" 
                                        name="Number"
                                        value={this.state.Kummerbox.Number}
                                        onChange={(e) => this.handleChange(e, 'Kummerbox')}
                                    />
                                    {this.state.formErrors.Number &&
                                    <span className='formError'>{this.state.formErrors.Number}</span>
                                    }
                                </div>
                            </div>                            
                            <div className="col-md-12 mb-medium">
                                <div className="form-group">
                                    <textarea 
                                        style={{ height: '150px' }}
                                        className="form-control" 
                                        placeholder="Was willst du uns sagen oder fragen? *" 
                                        name="Message"
                                        value={this.state.Kummerbox.Message}
                                        onChange={(e) => this.handleChange(e, 'Kummerbox')}
                                    />
                                    {this.state.formErrors.Message &&
                                    <span className='formError'>{this.state.formErrors.Message}</span>
                                    }
                                </div>
                            </div>
                        </>
                        }

                        {this.state.type &&
                        <>
                            <div className="col-md-12 mb-medium">
                                <div className="form-check">
                                    <input 
                                        type="checkbox" 
                                        name="privacyChecked"
                                        checked={this.state.privacyChecked} 
                                        onChange={this.handleCheckbox}
                                        id="privacyChecked" 
                                    />
                                    <label className="form-check-label" htmlFor="privacyChecked">
                                        <p>Die <a href="https://www.nbmb-verband.de/index.php/form/get_form/form_id/datenschutzerklaerung" target="_blank">Datenschutzerklärung</a> habe ich gelesen und zur Kenntnis genommen.</p>
                                    </label>
                                    {this.state.formErrors.privacyChecked &&
                                    <div><span className='formError'>{this.state.formErrors.privacyChecked}</span></div>
                                    }
                                </div>
                            </div>

                            <div className="col-md-6 align-right">
                                <button type="button" className="btn submit-button" onClick={this.handleSubmit} >Absenden</button>
                            </div>
                        </>
                        }
                    </div>
                    
                </div>
            </div>
        );
    }
}

export default Feedback;