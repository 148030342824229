import React from 'react';
import queryString from 'query-string';
import AccountLogin from './AccountLogin';
import Account from './Account';
import HttpService from '../../services/http.service';

export class AccountProvider extends React.Component {
  params = {};
  httpService;

  constructor(props) {
    super(props);

    this.state = { 
      authenticated: false,
      loading: false,
      feedback: '',
      data: undefined
    };

    this.httpService = new HttpService();
  }

  componentDidMount() {
    this.params = queryString.parse(this.props.location.search);
    if (('LoggedIn' in this.params)) {
      this.authenticate();
    }
  }

  authenticate() {
    if (!('Code' in this.params) || !('Number' in this.params) || !this.params.Code || !this.params.Number || !['A', 'M', 'L', 'B'].includes(this.params.Number[0])) {
      return this.setState({ 
        feedback: 'Lehrgangsnummer und/oder Freischaltcode nicht korrekt. Bitte überprüfen Sie die Lehrgangsnummer und/oder den Freischaltcode. Der Freischaltcode wird von der Lehrgangsleitung vor Beginn eines Lehrgangs zur Verfügung gestellt. Sollte Ihr Eingabe dennoch korrekt sein, wenden Sie sich bitte direkt an die Lehrgangsleitung. Den Kontakt finden Sie in der Bestätigungsemail Ihrer Anmeldung oder in den Lehrgangsunterlagen.' 
      });
    }

    this.setState({ loading: true });

    this.httpService.getAccountAttachments(this.params)
      .then((res) => {
        this.setState({
          loading: false,
          authenticated: true,
          data: res.data
        });
      })
      .catch(err => {
        this.setState({ 
          authenticated: false,
          loading: false, 
          feedback: 'Lehrgangsnummer und/oder Freischaltcode nicht korrekt. Bitte überprüfen Sie die Lehrgangsnummer und/oder den Freischaltcode. Der Freischaltcode wird von der Lehrgangsleitung vor Beginn eines Lehrgangs zur Verfügung gestellt. Sollte Ihr Eingabe dennoch korrekt sein, wenden Sie sich bitte direkt an die Lehrgangsleitung. Den Kontakt finden Sie in der Bestätigungsemail Ihrer Anmeldung oder in den Lehrgangsunterlagen.' 
        });
      });

  }

  handleLogin(loginFormData) {
    window.location.href = `/intern?Number=${loginFormData.courseNumber}&Code=${loginFormData.code}&LoggedIn=1`;
  }

  render() {
    return (
      this.state.loading ? 
      <div></div> :
      this.state.authenticated ? 
      <Account data={ this.state.data } secret={ this.params.Code } courseNumber={ this.params.Number } /> : 
      <div>
        <AccountLogin onLogin={ this.handleLogin } />
        <p className="mb-x-large">{ this.state.feedback }</p>
      </div>
    );
  }
}

export default AccountProvider;