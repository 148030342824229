import React from 'react';
import KurseTable from '../KurseTable/KurseTable';
import HttpService from '../../services/http.service';

export class LandingPageCourses extends React.Component {
  httpService;
  courses;
  
  constructor(props) {
    super(props);

    this.state = {
      courses: [],
      coursesCount: 0
    };

    this.httpService = new HttpService();
  }

  componentDidMount() {
    const courseNumbers = this.props.match.params.courseNumbers.split('-');
    this.httpService.getAllCourses()
      .then(response => {
        this.courses = response.filter(course => courseNumbers.includes(course.number));
        this.filterCourses();
      });
  }

  handleSearchChange = (ev) => {
    this.setFilterSearchURLParams(ev.target.value);
    this.filterCourses();
  }

  setFilterSearchURLParams = (search) => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set("Suche", search);
    const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
    window.history.pushState(null, '', newRelativePathQuery);
  }

  resetSearch = () => {
    this.setFilterSearchURLParams('');
    this.filterCourses();
  }

  filterCourses() {
    let params = new URLSearchParams(document.location.search.substring(1));
    let searchParam = params.get("Suche");
    let courses = [ ...this.courses ];

    if (searchParam && searchParam.length >= 2) {
      courses = this.courses.filter(course => this.searchInCourse(course, searchParam));
    }
    
    this.setState({ 
      coursesCount: courses.length,
      courses 
    });
  }

  searchInCourse = (course, searchKeyword) => {
    const stringToSearchIn = `${course.name.toLowerCase()} ${course.description.toLowerCase()} ${course.location.toLowerCase()} ${course.number.toLowerCase()}`;
    return stringToSearchIn.includes(searchKeyword.toLowerCase());
  }

  handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      this.jumpToTable();
    }
  }

  jumpToTable = () => {
    const el = document.getElementById("course-table");
    el.scrollIntoView(); 
  }

  render() {
    let params = new URLSearchParams(document.location.search.substring(1));
    let searchParam = params.get("Suche");

    return (
      <div>

        <div className="row mb-medium">
          <div className="col align-center">
            <div className="input-group" id="search-field">
              <input 
                type="search" 
                className="form-control"
                placeholder="Kurse/Fortbildungen nach Begriff/Stichwort durchsuchen…" 
                value={ searchParam } 
                onChange={this.handleSearchChange}
                onKeyUp={ this.handleSearchKeyUp }
              />
              <div className="input-group-append" style={{ cursor: 'pointer' }} onClick={ this.jumpToTable }>
                <div className="input-group-text"><i className="fa navigation-icon fa-search"></i></div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-medium">
          <div className="col align-center">
            <button type="button" className="btn submit-button reset-button" style={{ textTransform: 'none'}} onClick={ this.resetSearch } disabled={ !searchParam }>Suche zurücksetzen</button>
            <a href="#course-table" className="btn submit-button reset-button treffer-button small">{ this.state.coursesCount } Treffer</a>
          </div>
        </div>

        <div className="boxed">
          <KurseTable
            courses={ this.state.courses }
            bookmarkedCourses={ [] }
            disableBookmarks={ true }
          />
        </div>
        <div className="row mb-x-large">
          <div className="col">
            <div className="seperator"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default LandingPageCourses;