import moment from 'moment';

export const createCalenderData = (course) => {
    const eventTitle = course.name;
    const eventDescription = createDescription(course.description, course.descriptions);

    const events = course.descriptions.map((description) => createEvent(description, eventTitle, eventDescription, description.location || course.location));

    const calenderStart =  [
        "BEGIN:VCALENDAR",
        "VERSION:2.0"
    ];

    const calenderEnd =  [
        "END:VCALENDAR"
    ];

    const calender = [ calenderStart.join("\n"), events.join("\n"), calenderEnd.join("\n") ].join("\n");

    return calender;
};

const createDescription = (description, eventDates) => {
    const eventDescription = description.replace(/\n/g, '\\n');
    const dates = eventDates.map(eventDate => `${eventDate.date}: ${eventDate.description}`);
    return eventDescription + '\\n' + '\\n' + dates.join('\\n');
}

const createEvent = (description, eventTitle, eventDescription, eventLocation) => {
    const splitStartEnd = description.date.split('-');
    let startFormated = moment(splitStartEnd[0], 'DD.MM.YYYY').format('YYYYMMDD');
    const end = splitStartEnd.length > 1 ? splitStartEnd[1] : splitStartEnd[0];
    let endFormated;

    if (description.time) {
        endFormated = moment(end, 'DD.MM.YYYY').format('YYYYMMDD');
        const timeSplit = description.time.split('-');
        startFormated += 'T' + timeSplit[0].replace(':', '') + '00';
        endFormated += 'T' + timeSplit[1].replace(':', '') + '00';
    }
    else {
        // ganztägig!
        endFormated = moment(end, 'DD.MM.YYYY').add(1, 'd').format('YYYYMMDD');
    }

    return [
        "BEGIN:VEVENT",
        "DTSTART:" + startFormated,
        "SUMMARY:" + eventTitle,
        "DTEND:" + endFormated,
        "DESCRIPTION:" + eventDescription,
        "LOCATION:" + eventLocation,
        "END:VEVENT"
    ].join("\n");
};