import axios from 'axios';

export class HttpService {
  URL_MAP = {
    'L': 'https://training.nbmb-verband.de/',
    'B': 'https://training.bvv-verband.de/',
    'A': 'https://training.asm-verband.de/',
    'M': 'https://training.mon-verband.de/'
  };

  getAllCourses() {
    const requests = Object.values(this.URL_MAP).map((url) => axios.get(url + 'trainings'))
    return Promise.all(requests)
      .then((courses) => {
        return courses.reduce((courses, coursesByAssociation) => {
          const coursesByAssociationWithCourseNumberAsInt = coursesByAssociation.data
            .filter(course => course.hasOwnProperty('tags'))
            .filter(course => course.public)
            .map(course => ({
              ...course,
              numberAsInt: parseInt(course.number.substr(1, course.number.length))
            }));
          return [ ...courses, ...coursesByAssociationWithCourseNumberAsInt ];
        }, []);
      });
  }

  getCoursesByAssociation(association) {
    return axios.get(this.URL_MAP[association] + 'trainings');
  }

  sendInsertion(data) {
    const axiosConfig = {
      headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
      }
    };
    return axios.post('https://www.kurs-finder.de/mail/mail.php', data, axiosConfig);
  }

  createRegistration(requestData, association) {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }

    return axios.post(this.URL_MAP[association] + 'application', requestData, config);
  }

  uploadAttachments(token, courseNumber, union, formData) {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    };
    const associationMap = {
      '15': 'https://training.nbmb-verband.de/',
      '19': 'https://training.bvv-verband.de/',
      '14': 'https://training.mon-verband.de/',
      '13': 'https://training.asm-verband.de/'
    };
    const requestData = new FormData();
    requestData.append('trainingName', courseNumber);
    Object.keys(formData).forEach(key => {
      requestData.append(key, formData[key]);
    });
    return axios.post(`${associationMap[union]}application/upload?token=${token}`, requestData, config);
  }

  getAccountAttachments(params) {
    const association = params.Number.substr(0, 1);
    return axios.get(`${this.URL_MAP[association]}training/attachments/secret?Number=${params.Number}&Secret=${params.Code}`);
  }

  sendCounter(courseNumber) {
    const baseUrl = this.URL_MAP[courseNumber[0]];
    return axios.post(`${baseUrl}training/visited?Number=${courseNumber}`);
  }

  checkCourseNumber(courseNumber) {
    const baseUrl = this.URL_MAP[courseNumber[0]];
    return axios.get(`${baseUrl}training/verify?Number=${courseNumber}`);
  }

  sendQuestion(data) {
    const baseUrl = this.URL_MAP[data.Number[0]];

    const requestData = new FormData();
    Object.keys(data).forEach(key => {
      requestData.append(key, data[key]);
    });

    return axios.post(`${baseUrl}training/question`, requestData);
  }

  sendFeedback(data) {
    const baseUrl = this.URL_MAP[data.Number[0]];
    
    const requestData = new FormData();
    Object.keys(data).forEach(key => {
      requestData.append(key, data[key]);
    });

    return axios.post(`${baseUrl}training/feedback`, requestData);
  }

  sendKummerbox(data) {
    const baseUrl = data.Number[0] ? this.URL_MAP[data.Number[0]] : this.URL_MAP['L'];

    const requestData = new FormData();
    Object.keys(data).forEach(key => {
      requestData.append(key, data[key]);
    });

    return axios.post(`${baseUrl}training/grief`, requestData);
  }
}

export default HttpService;