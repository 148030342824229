import React from 'react';

export class Filter extends React.Component {
  constructor(props) {
    super(props);

    this.state = { collapsed: this.props.data.collapsed };

    this.toggleCollapsed = this.toggleCollapsed.bind(this);
  }

  toggleCollapsed() {
    const currentCollapsed = this.state.collapsed;
    this.setState({ collapsed: !currentCollapsed });
  }

  render() {
    return (
      <div className="col-md-4">
        <div className="filter">
        {
          this.props.data.collapseable ? 
          <div className="filter-headline cursor-pointer" onClick={this.toggleCollapsed}>
            <p><i className={'fa filter-icon ' + (this.state.collapsed ? 'fa-angle-right' : 'fa-angle-down')}></i></p>

            { 
              this.props.data.headline === 'Leistungsabzeichen (Junior & D-Abzeichen)' ?
              <p className="filter-headline-text">Leistungsabzeichen<br/>(Junior & D-Abzeichen)</p>
              :
              <p className="filter-headline-text">{ this.props.data.headline }</p>
            }          

          </div> : ''
        }
        {
          !this.state.collapsed ?
          <div className="filter-options">
            {
              this.props.data.options.map((option) => {
                return (
                  <div className="filter-option" key={option.key}>
                    <div className="custom-checkbox" onClick={ () => this.props.onFilterChange(option.key) }>

                    {
                      this.props.activeFilter.includes(option.key) ?
                      <div className="custom-checkbox-checked"></div>
                      : ''
                    }

                    </div>
                    <label className="form-check-label" onClick={ () => this.props.onFilterChange(option.key)}>
                      {option.title}
                    </label>
                  </div>
              )})
            }
          </div> : ''
        }
        </div>
      </div>
    );
  }
}

export default Filter;