import React from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import NavigationMobile from '../NavigationMobile/NavigationMobile';
import nbmbLogo from '../../assets/img/Logo_NBMB_grau.jpg';
import blaeserjugendLogo from '../../assets/img/Logos_NB_grau.jpg';
import headerlargeImg from '../../assets/img/header-large.jpg';
import headerMediumImg from '../../assets/img/header-medium.jpg';
import headerSmallImg from '../../assets/img/header-small.jpg';
import headerBadge from '../../assets/img/header-badge.png';

function Header(props) {
  return (
    <div>
      <div className="row header-logo-row">
      {/*
        <div className="col">
          <a href="http://www.nbmb-online.de/" target="_blank">
            <img src={nbmbLogo} className="header-logo" alt="Nordbayerischer Musikbund e.V. Logo" />
          </a>
        </div>
        <div className="col header-logo-col-last align-right">
          <a href="http://www.blaeserjugend.de/" target="_blank">
            <img src={blaeserjugendLogo} className="header-logo" alt="Nordbayerische Bläserjugend e.V. Logo" />
          </a>
        </div>
      */}
        <NavigationMobile />
      </div>

    {
      props.location.pathname !== '/' ?
      <>
      <div className="row">
        <div className="col align-center">
          <Link to="/">
            <img className="header-badge-standalone" src={ headerBadge } />
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col align-center">
          <Link to="/">
            zurück zum Kursfinder
          </Link>
        </div>
      </div>
      </>

      :

      <div>
        <div className="row header-badge-row">
          <div className="col align-center header-badge">
            <img src={ headerBadge } />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <header className="mb-small">
              <img src={ headerlargeImg } alt="Logo" className="header-large-image" />
              <img src={ headerMediumImg } alt="Logo" className="header-medium-image" />
              <img src={ headerSmallImg } alt="Logo" className="header-small-image" />
            </header>
          </div>
        </div>
      </div>
    }

    </div>
  );
}

export default withRouter(Header);