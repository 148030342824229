import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import HttpService from '../../services/http.service';

const getDeaultState = () => ({
    name: '',
    club: '',
    email: '',
    message: '',
    privacyChecked: false,
    showFormValidationErrorMessage: false,
    saving: false
});

export class Insertion extends React.Component {
    httpService;

    constructor(props) {
        super(props);

        this.state = { ...getDeaultState() };

        this.httpService = new HttpService();
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(ev) {
        const target = ev.target;
        this.setState({ [target.name]: target.value });
    }

    handleCheckbox(ev) {
        const target = ev.target;
        this.setState({ [target.name]: target.checked });
    }

    handleSubmit() {
        // getDeaultState ...
        if (this.validateForm()) {
            this.setState({ showFormValidationErrorMessage: false });

            this.setState({ saving: true });
            const data = {
                name: this.state.name,
                club: this.state.club,
                email: this.state.email,
                message: this.state.message
            };


            this.httpService.sendInsertion(data)
                .then((res) => {
                    if (res.data === true) {
                        alert('Vorschlag für Lehrgang/Fortbildung wurde erfolgreich versendet');
                        window.location.href = '/';
                    }
                    else {
                        this.setState({ saving: false });
                        alert("Beim Versenden der Email ist ein Fehler passiert, bitte versuchen Sie es noch einmal.")
                    }
                });
        }
        else {
            this.setState({ showFormValidationErrorMessage: true });
        }
    }

    validateForm() {
        if (this.state.name && this.state.email && this.state.message && this.state.privacyChecked) {
            return true;
        }
        return false;
    }

    render() {
        return (
            <div className="boxed">
                <div className="mt-x-large mb-x-large">

                    <div className="row row-find-headline mb-medium">
                        <div className="col align-center">
                            <h1>Kurs / Fortbildung vorschlagen</h1>
                            <p>Du möchtest dich für einen Kurs bzw. eine Fortbildung anmelden und findest kein passendes Angebot? <br/>Bitte nenne uns Deinen Fortbildungswunsch und – wenn möglich – auch dessen Inhalte. Gerne kannst Du auch eine Region angeben, in dem die Fortbildung stattfinden sollte. Sobald genügend gleichartige Interessenten für eine derartige Fortbildung vorliegen, wird ein entsprechendes Fortbildungsangebot organisiert. Du erhältst dann per Email eine entsprechende Benachrichtigung.</p>
                        </div>
                    </div>

                    <div className="form-row row-insertion-form mb-x-large">
                        <div className="col-md-6">
                            <div className="form-group">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Name, Vorname *"
                                    name="name"
                                    value={this.state.name} 
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Musikverein / Musikschule" 
                                    name="club"
                                    value={this.state.club}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Email-Adresse *" 
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-12 mb-medium">
                            <div className="form-group">
                                <textarea 
                                    style={{ height: '150px' }}
                                    className="form-control" 
                                    placeholder="Textfeld *" 
                                    name="message"
                                    value={this.state.message}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-12 mb-medium">
                            <div className="form-check">
                                <input 
                                    type="checkbox" 
                                    name="privacyChecked"
                                    checked={this.state.privacyChecked} 
                                    onChange={this.handleCheckbox}
                                    id="privacyChecked" 
                                />
                                <label className="form-check-label" htmlFor="privacyChecked">
                                    <p>Die <a href="https://www.nbmb-verband.de/index.php/form/get_form/form_id/datenschutzerklaerung" target="_blank">Datenschutzerklärung</a> habe ich gelesen und zur Kenntnis genommen.</p>
                                </label>
                            </div>
                        </div>

                        { this.state.showFormValidationErrorMessage && (!this.state.name || !this.state.email || !this.state.message) ?
                            <div className="col-md-12">
                                <p>Bitte die Felder Name, Email und Textfeld ausfüllen.</p>
                            </div> : ''
                        }

                        { this.state.showFormValidationErrorMessage && !this.state.privacyChecked ?
                            <div className="col-md-12">
                                <p>Bitte die Datenschutzerklärung akzeptieren.</p>
                            </div> : ''
                        }

                        {/*
                        <div className="col-md-6">
                            <ReCAPTCHA
                                // sitekey="6LfhsbgUAAAAAJt0KTwBe3KXgV-vktOu2JyXl9-Z" // public key
                                sitekey="6LfhsbgUAAAAADgdnzTmi6yN-qdlwrTSFcju0VFt" // secret key
                                onChange={ (value) => console.log("Captcha value:", value) }
                            />
                        </div>
                        */}

                        <div className="col-md-6 align-right">
                            <button type="button" className="btn submit-button" onClick={this.handleSubmit} >Absenden</button>
                        </div>
                    </div>
                    
                </div>
            </div>
        );
    }
}

export default Insertion;