import React from 'react';
import moment from 'moment';
import D1Abzeichen from '../assets/img/D1-Abzeichen.jpg';
import D2Abzeichen from '../assets/img/D2-Abzeichen.jpg';
import D3Abzeichen from '../assets/img/D3-Abzeichen.jpg';
import D1AbzeichenPlus from '../assets/img/D1-Abzeichen-plus.png';
import D2AbzeichenPlus from '../assets/img/D2-Abzeichen-plus.png';
// import Erwachsene from '../assets/img/erwachsene.jpg';
import Kinder from '../assets/img/kinder.png';

export const showDate = (dates) => {
  if (!dates || !dates.length) {
    return true;
  }
  const firstDate = moment(dates[0], "DD.MM.YYYY");
  if (!firstDate.isValid()) {
    return true;
  }
  return firstDate.isBefore(moment().add(3, 'y'));
}

export const sortDates = (dates = []) => {
  const datesRangesWithStartDate = dates.map(date => {
    const range = date.split('-');
    return {
      startDateNative: moment(range[0], "DD.MM.YYYY").toDate(),
      date
    };
  });
  datesRangesWithStartDate.sort((a, b) => a.startDateNative - b.startDateNative);
  return datesRangesWithStartDate;
}

export const sortDateDescriptions = (events = []) => {
  const eventsWithNativeDate = events.map(event => {
    const range = event.date.split('-');
    const startDateNative = moment(range[0], "DD.MM.YYYY").toDate();
    return {
      startDateNative: startDateNative,
      date: event.date,
      time: event.time,
      description: event.description,
      location: event.location
    };
  });
  eventsWithNativeDate.sort((a, b) => a.startDateNative - b.startDateNative);
  return eventsWithNativeDate;
}

export const showOccupancy = (course) => {
  const deadline = moment(course.deadline, "DD.MM.YYYY");
  if (!deadline.isValid()) {
    return course.occupancy;
  }
  const now = moment();
  if (now.isSameOrBefore(deadline, 'd')) {
    return course.occupancy;
  }
  return 'Der Anmeldezeitraum für diese Maßnahme ist abgelaufen.';
}

export const isRegistrationPeriodActive = (course) => {
  const deadline = moment(course.deadline, "DD.MM.YYYY");
  if (!deadline.isValid()) {
    return false;
  }
  const now = moment();
  return (now.isSameOrBefore(deadline, 'd'));
}

export const getTableImg = (course) => {
  if (course.type) {
    switch (course.type) {
      case 'D1':
          return <img src={ D1Abzeichen } className="abzeichen" />;
          
      case 'D2':
          return <img src={ D2Abzeichen } className="abzeichen" />;
              
      case 'D3':
          return <img src={ D3Abzeichen } className="abzeichen" />;
    }
  }

  // Plus Abzeichen: wird temporär über Kursbezeichnung gelöst und dann später von type prop abgelöst
  if (course.name.toLowerCase().includes('d1') && course.name.toLowerCase().includes('plus')) {
    return <img src={ D1AbzeichenPlus } className="abzeichen" />;
  }
  if (course.name.toLowerCase().includes('d2') && course.name.toLowerCase().includes('plus')) {
    return <img src={ D2AbzeichenPlus } className="abzeichen" />;
  }

  // Zielgruppen Abzeichen
  if (course.tags && course.tags.length && course.tags.includes('10_00')) {
    return <img src={ Kinder } className="abzeichen" />;
  }
  /*
  if (course.tags && course.tags.length && course.tags.includes('10_01')) {
    return <img src={ Erwachsene } className="abzeichen" />;
  }
  */
  return '';
}

export const getAssociation = (courseNumber) => {
  const association = courseNumber.substr(0, 1);
  switch (association) {
    case 'L':
      return '(NBMB)';
    case 'B':
      return '(BVV)';
    case 'M':
      return '(MON)';
    case 'A':
      return '(ASM)';
    default:
      return '';
  }
};

export const isCourseNumber = (input) => {
  const regex = /^[ablmABLM][0-9]{5}$/;
  return regex.test(input);
}

export const isOwnderId = (input) => {
  const regex = /^[0-9]{5}[A-Z]{1}[0-9]{3}$/;
  return regex.test(input);
}