import React from 'react';
import { Link } from "react-router-dom";

export function BookingConfirmed() {
  const email = localStorage.getItem("confirmation-email");
  return (
    <div className="boxed">
      <div className="mt-x-large mb-x-large">

          <div className="row row-find-headline mb-medium">
              <div className="col align-center">
                <h1>In einem weiteren Schritt ist eine Bestätigung Ihrer Anmeldung per Email erforderlich.</h1>
                <p><b>Sie erhalten in Kürze eine E-Mail auf die von Ihnen angegebene E-Mail-Adresse: { email }</b></p>
                <p className="warning">Bitte bestätigen Sie innerhalb von 48 Stunden Ihre Anmeldung durch einen Klick auf den entsprechenden Link in der E-Mail. Die Mail kann u.U. auch im Spam-Ordner ankommen</p>
                <p>Erst nach dieser Bestätigung sind Sie für den Kurs verbindlich angemeldet. Ohne eine Bestätigung wird Ihre Anmeldung nach 48 Stunden automatisch wieder gelöscht.</p>
              </div>
          </div>

          <div className="row">
              <div className="col align-center">
                <Link to="/"><button type="button" className="btn submit-button">Ok</button></Link> 
              </div>
          </div>

      </div>
    </div>
  );
}

export function BookingEmailConfirmed() {
  return (
    <div className="boxed">
      <div className="mt-x-large mb-x-large">

          <div className="row row-find-headline mb-medium">
              <div className="col align-center">
                <h1>Sie sind jetzt angemeldet!</h1>
                <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
                  Sie erhalten in Kürze eine Email mit der Bestätigung Ihrer Anmeldung.<br/>
                  Weitere Infos erhalten Sie vor Kursbeginn direkt von der Lehrgangsleitung.
                </p>
              </div>
          </div>

          <div className="row">
              <div className="col align-center">
                <Link to="/"><button type="button" className="btn submit-button">Ok</button></Link> 
              </div>
          </div>

      </div>
    </div>
  );
}

export function BookingEmailAlreadyConfirmed() {
  return (
    <div className="boxed">
      <div className="mt-x-large mb-x-large">

          <div className="row row-find-headline mb-medium">
              <div className="col align-center">
                <h1>Ihre Anmeldung über das Kurs-Portal www.kurs-finder.de wurde bereits von Ihnen bestätigt.</h1>
                <p>Sie erhalten vor Kursbeginn weitere Informationen per Email direkt durch die Lehrgangsleitung.</p>
              </div>
          </div>

          <div className="row">
              <div className="col align-center">
                <Link to="/"><button type="button" className="btn submit-button">Ok</button></Link> 
              </div>
          </div>

      </div>
    </div>
  );
}

export function BookingErrorAttachments() {
  return (
    <div className="boxed">
      <div className="mt-x-large mb-x-large">

          <div className="row row-find-headline mb-medium">
              <div className="col align-center">
                <h1>Ein Upload von weiteren Anlagen zu Ihrer Anmeldung ist derzeit nicht möglich.</h1>
                <p>Sollten Sie Ihre Anmeldung vor mehr als 48 Stunden über das Kursportal www.kurs-finder.de gesendet und bis dato nicht bestätigt haben, ist eine erneute Anmeldung erforderlich. Die bisherigen Anmeldedaten wurden aufgrund der fehlenden Bestätigung wieder gelöscht.</p>
              </div>
          </div>

          <div className="row">
              <div className="col align-center">
                <Link to="/"><button type="button" className="btn submit-button">Ok</button></Link> 
              </div>
          </div>

      </div>
    </div>
  );
}

export function BookingConfirmAttachments() {
  return (
    <div className="boxed">
      <div className="mt-x-large mb-x-large">

          <div className="row row-find-headline mb-medium">
              <div className="col align-center">
                <h1>Die Unterlagen wurden per Upload Ihrer Anmeldung hinzugefügt.</h1>
                <p>Sie erhalten vor Kursbeginn weitere Informationen per Email direkt durch die Lehrgangsleitung.</p>
              </div>
          </div>

          <div className="row">
              <div className="col align-center">
                <Link to="/"><button type="button" className="btn submit-button">Ok</button></Link> 
              </div>
          </div>

      </div>
    </div>
  );
}