const filterMap = {
  "00_00": {
      "de_de": "Traditionelle Blasmusik"
  },
  "00_01": {
      "de_de": "Spielleutemusik"
  },
  "00_02": {
      "de_de": "Marschmusik"
  },
  "00_03": {
      "de_de": "Wertungsspiele / Wettbewerbe"
  },
  "00_04": {
      "de_de": "Vereinsführung"
  },
  "01_00": {
      "de_de": "Instrumentale Fortbildungen"
  },
  "02_00": {
      "de_de": "Juniorabzeichen"
  },
  "02_01": {
      "de_de": "D1 - Leistungsabzeichen in Bronze"
  },
  "02_02": {
      "de_de": "D2 - Leistungsabzeichen in Silber"
  },
  "02_03": {
      "de_de": "D3 - Leistungsabzeichen in Gold"
  },
  "03_00": {
      "de_de": "Jugendleiterseminare (Juleica)"
  },
  "03_01": {
      "de_de": "Angebote für Ehrenamtliche"
  },
  "03_02": {
      "de_de": "Freizeiten und Jugendaktionen"
  },
  "04_00": {
      "de_de": "Registerführer, Dirigenten, Orchester- & Ensembleleiter"
  },
  "05_00": {
      "de_de": "Solo-/Duo-Wettbewerb"
  },
  "05_01": {
      "de_de": "Kammermusik-Wettbewerb"
  },
  "05_02": {
      "de_de": "Wertungsspiele für Orchester"
  },
  "05_03": {
      "de_de": "Wettbewerbe für Orchester"
  },
  "06_00": {
      "de_de": "Nordbayerisches Musikcamp"
  },
  "06_01": {
      "de_de": "Bläser-/Orchesterwochen"
  },
  "06_02": {
      "de_de": "Kreisorchester (Landkreise)"
  },
  "06_03": {
      "de_de": "Bezirksorchester (Bezirke)"
  },
  "06_04": {
      "de_de": "Nordbayerisches Jugendblasorchester"
  },
  "06_05": {
      "de_de": "Nordbayerisches Spielleuteorchester"
  },
  "06_06": {
      "de_de": "Nordbayerische Brass Band"
  },
  "07_00": {
      "de_de": "Musik- & Instrumentalpädagogik, Bläserklasse"
  },
  "08_00": {
      "de_de": "Sonderaktionen"
  },
  "09_00": {
      "de_de": "Mittelfranken"
  },
  "09_01": {
      "de_de": "Oberfranken"
  },
  "09_02": {
      "de_de": "Oberpfalz"
  },
  "09_03": {
      "de_de": "Unterfranken"
  },
  "10_00": {
      "de_de": "Kinder / Jugendliche"
  },
  "10_01": {
      "de_de": "Erwachsene / Senioren"
  }
  /*,
  "10_02": {
      "de_de": "Senioren"
  }*/,
  "10_03": {
      "de_de": "Traditionelle Blasmusik / Marschmusik"
  },
  "10_04": {
      "de_de": "Volksmusik"
  },
  "10_05": {
      "de_de": "Spielleutemusik"
  },
  "10_06": {
      "de_de": "Brass Band"
  },
  "10_07": {
      "de_de": "Symphonische Blasmusik"
  },
  "10_08": {
      "de_de": "Big Band"
  },
  "11_00": {
      "de_de": "Holzblasinstrumente"
  },
  "11_01": {
      "de_de": "Blechblasinstrumente"
  },
  "11_02": {
      "de_de": "Schlagwerk / Percussion"
  },
  "11_03": {
      "de_de": "Instrumente für Spielleute"
  },
  "11_04": {
      "de_de": "Alphorn"
  }
};

export const getMappedTags = (tags, type) => {
  const tagTypesToMap = {
    regions: ['09_00', '09_01', '09_02', '09_03'],
    targets: ['10_00', '10_01', '10_02', '10_03', '10_04', '10_05', '10_06', '10_07', '10_08'],
    instruments: ['11_00', '11_01', '11_02', '11_03', '11_04']
  };

  return tags && tags.length ? tags
    .filter(tag => tagTypesToMap[type].includes(tag))
    .filter(tag => filterMap[tag])
    .map(tag => filterMap[tag].de_de)
    .join(', ') : '';
}

export const filter = [
  {
    id: 1,
    headline: "Vereinscoaching / Vereinsführung",
    options: [
      { title: "Traditionelle Blasmusik", key: '00_00' },
      { title: "Spielleutemusik", key: '00_01' },
      { title: "Marschmusik", key: '00_02' },
      { title: "Wertungsspiele / Wettbewerbe", key: '00_03' },
      { title: "Vereinsführung", key: '00_04' }
    ],
    collapseable: true,
    collapsed: true
  },
  {
    id: 2,
    options: [
      { title: "Instrumentale Fortbildungen", key: '01_00' }
    ],
    collapseable: false
  },
  {
    id: 3,
    headline: "Leistungsabzeichen (Junior & D-Abzeichen)",
    options: [
      { title: "Juniorabzeichen", key: '02_00' },
      { title: "D1 - Leistungsabzeichen in Bronze", key: '02_01' },
      { title: "D2 - Leistungsabzeichen in Silber", key: '02_02' },
      { title: "D3 - Leistungsabzeichen in Gold", key: '02_03' }
    ],
    collapseable: true,
    collapsed: true
  },
  {
    id: 4,
    headline: "Jugendarbeit",
    options: [
      { title: "Jugendleiterseminare (Juleica)", key: '03_00' },
      { title: "Angebote für Ehrenamtliche", key: '03_01' },
      { title: "Freizeiten und Jugendaktionen", key: '03_02' }
    ],
    collapseable: true,
    collapsed: true
  },
  {
    id: 5,
    options: [
      { title: "Registerführer, Dirigenten, Orchester- & Ensembleleitung", key: '04_00' }
    ],
    collapseable: false
  },
  {
    id: 6,
    headline: "Wettbewerbe / Wertungsspiele",
    options: [
      { title: "Solo-/Duo-Wettbewerb", key: '05_00' },
      { title: "Kammermusik-Wettbewerb", key: '05_01' },
      { title: "Wertungsspiel für Orchester", key: '05_02' },
      { title: "Wettbewerbe für Orchester", key: '05_03' }
    ],
    collapseable: true,
    collapsed: true
  },
  {
    id: 7,
    headline: "Orchesterwochen & Auswahlorchester",
    options: [
      { title: "Nordbayerisches Musikcamp", key: '06_00' },
      { title: "Bläser-/Orchesterwochen", key: '06_01' },
      { title: "Kreisorchester (Landkreise)", key: '06_02' },
      { title: "Bezirksorchester (Bezirke)", key: '06_03' },
      { title: "Nordbayerisches Jugendblasorchester", key: '06_04' },
      { title: "Nordbayerisches Spielleuteorchester", key: '06_05' },
      { title: "Nordbayerische Brass Band", key: '06_06' }
    ],
    collapseable: true,
    collapsed: true
  },
  {
    id: 8,
    options: [
      { title: "Instrumental- & Musikpädagogik, Bläserklasse", key: '07_00' }
    ],
    collapseable: false
  },
  {
    id: 9,
    options: [
      { title: "Sonderaktionen", key: '08_00' }
    ],
    collapseable: false
  },
];

export const advancedFilter = [
  /*
  {
    id: 10,
    headline: "Regionen",
    options: [
      { title: "Mittelfranken", key: '09_00' },
      { title: "Oberfranken", key: '09_01' },
      { title: "Oberpfalz", key: '09_02' },
      { title: "Unterfranken", key: '09_03' }
    ],
    collapseable: true,
    collapsed: true
  },
  */
  {
    id: 11,
    headline: "Zielgruppe",
    options: [
      { title: "Kinder / Jugendliche", key: '10_00' },
      { title: "Erwachsene / Senioren", key: '10_01' },
      // { title: "Senioren", key: '10_02' },
      { title: "Traditionelle Blasmusik / Marschmusik", key: '10_03' },
      { title: "Volksmusik", key: '10_04' },
      { title: "Spielleutemusik", key: '10_05' },
      { title: "Brass Band", key: '10_06' },
      { title: "Symphonische Blasmusik", key: '10_07' },
      { title: "Big Band", key: '10_08' }
    ],
    collapseable: true,
    collapsed: true
  },
  {
    id: 12,
    headline: "Instrumentengattung",
    options: [
      { title: "Holzblasinstrumente", key: '11_00' },
      { title: "Blechblasinstrumente", key: '11_01' },
      { title: "Schlagwerk / Percussion", key: '11_02' },
      { title: "Instrumente für Spielleute", key: '11_03' },
      { title: "Alphorn", key: '11_04' }
    ],
    collapseable: true,
    collapsed: true
  }
];