import React from 'react';
import HttpService from '../../services/http.service';
import CourseInfo from './CourseInfo';
import CourseForm from './CourseForm';

export class CourseDetails extends React.Component {
  httpService;

  constructor(props) {
    super(props);

    this.state = {
      course: null,
      showForm: false,
      showDeadline: true,
      infoCodeError: '',
      secret: ''
    };

    this.httpService = new HttpService();
  }

  componentDidMount() {
    this.init()
  }

  async init () {
    const { courseNumber } = this.props.match.params;
    const association = courseNumber.substr(0, 1);
    const response = await this.httpService.getCoursesByAssociation(association)
    const course = response.data.find(course => course.number == courseNumber);
    if (course && course.number) {
      if (course.subsequent) {
        this.checkSecret(course);
      }
      this.setState({ course });
      this.httpService.sendCounter(course.number).catch(() => {});
    }
  }

  async checkSecret(course) {
    const params = new URLSearchParams(document.location.search.substring(1));
    const secret = params.get("Secret");
    if (secret) {
      try {
        await this.httpService.getAccountAttachments({ Number: course.number, Code: secret });
        this.setState({ showForm: true, showDeadline: false, secret });
      } catch (e) {
        return this.setState({ 
          infoCodeError: 'Du angegebene Code ist leider nicht korrekt.' 
        });
      }
    }
  }
  
  render() {
    return (
      <div>
        
        <div>
          <CourseInfo course={ this.state.course } showDeadline={this.state.showDeadline} />
        {
          this.state.course && this.state.course.fields && this.state.course.fields.length && this.state.course.bookable && (this.state.course.recruting || this.state.showForm)  ?
          <CourseForm course={ this.state.course } secret={this.state.secret} /> : 
          <div className="mb-x-large"></div>
        }

        {
          this.state.course && this.state.course.bookable === false ?
          <div className="mt-x-large mb-x-large">
            <p>Eine Online-Anmeldung ist für diese Maßnahme derzeit noch nicht bzw. nicht mehr möglich. Wir bitten um Verständnis.</p>
            <button type="button" className="btn submit-button" onClick={ () => {  window.history.back(); }}>zurück</button>
          </div> : ''
        }

        {
          this.state.course && this.state.course.bookable && !this.state.showForm &&
          <div className="mt-x-large mb-x-large">
            
            { !this.state.course.future &&
              <p>Die Maßnahme hat bereits begonnen. Eine Online-Anmeldung ist daher nicht mehr möglich. Wir bitten um Verständnis.</p>
            }
            { this.state.course.future && !this.state.course.recruting && 
              <p>Der Anmeldezeitraum für die Maßnahme ist leider abgelaufen. Eine Online-Anmeldung ist daher nicht mehr möglich. Wir bitten um Verständnis.</p>
            }
            {this.state.infoCodeError !== "" && 
              <p style={{color: "rgb(221,78,75)"}}><b>{this.state.infoCodeError}</b></p>
            }
            
            <button type="button" className="btn submit-button" onClick={ () => {  window.history.back(); }}>zurück</button>
          </div>
        }
        </div>
      </div>
    );
  }
}

export default CourseDetails;