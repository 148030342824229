import React from 'react';
import HttpService from '../../services/http.service';

export class Account extends React.Component {
  httpService;

  constructor(props) {
    super(props);
    this.state = {
      course: null
    };

    this.httpService = new HttpService();
  }
  
  render() {
    return (
      <div>

        <div className="boxed">
          <div className="mt-x-large mb-x-large">

            <div className="row row-find-headline mb-medium">
                <div className="col ">
                    <h1>{ this.props.courseNumber } - { this.props.data ? this.props.data.name : '' }</h1>
                    <p>Informationen für die Teilnehmerinnen und Teilnehmer</p>
                    <p>{ 
                        this.props.data ? 
                          this.props.data.note.split('\n').map((item, key) => {
                            return <span key={key}>{item}<br/></span>
                          })
                           : '' 
                        }
                    </p>
                </div>
            </div>

            <div className="row">

          {
            this.props.data.files.map(attachment => (
              <div className="col-md-12">
                <p className="course-file">
                  <i className="fa fa-download"></i>
                  <a href={ `${attachment.link}/secret/${this.props.secret}` } >{ attachment.name }</a>
                </p>
              </div>
            ))
          }

            </div>

          </div>
        </div>

        <div className="row mb-x-large">
          <div className="col">
            <div className="seperator"></div>
          </div>
        </div>

      </div>
    );
  }
}

export default Account;