import React from 'react';
import { NavLink } from "react-router-dom";

function Navigation () {
  return (
    <nav className="row mb-small main-menu">
      <div className="col-xl-4 col-md-6 nav-link">
        <p><i className="fa navigation-icon fa-angle-right"></i></p>
        <p className="nav-link-text"><NavLink to="/kurs-vorschlagen" activeClassName='is-active'>Kurs/Fortbildung vorschlagen</NavLink></p>
      </div>
      <div className="col-md-4 nav-link">
        <p><i className="fa navigation-icon fa-angle-right"></i></p>
        <p className="nav-link-text"><NavLink to="/briefkasten-kummerbox" activeClassName='is-active'>Briefkasten / Kummerbox</NavLink></p>
      </div>
      <div className="col-xl-4 col-md-6 nav-link">
        <p><i className="fa navigation-icon fa-angle-right"></i></p>
        <p className="nav-link-text"><NavLink to="/intern" activeClassName='is-active'>Interner Bereich für Kursteilnehmer</NavLink></p>
      </div>
    </nav>
  );
}

export default Navigation;