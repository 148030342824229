import React from 'react';
import Modal from "react-bootstrap/Modal";
import { getMappedTags } from '../../services/filters';
import { showDate, showOccupancy, sortDateDescriptions } from '../../services/common.helper';
import { createCalenderData } from '../../services/calender.helper';

export function CourseInfo({ course, showDeadline }) {
  const courseLogoRef = React.useRef(null);
  const [ showCourseLogoModal, setShowCourseLogoModal ] = React.useState(false);

  const setCourseLogoHeight = () => {
    if (courseLogoRef.current.height > courseLogoRef.current.width) {
      courseLogoRef.current.height = courseLogoRef.current.width;
    }
  };

  const downloadCalender = () => {
    const calenderData = createCalenderData(course);
    const blob = new Blob([calenderData], {type: 'text/calendar;charset=utf-8'});
    if (/msie\s|trident\/|edge\//i.test(window.navigator.userAgent)) {
        // Open/Save link in IE and Edge
        window.navigator.msSaveBlob(blob, 'download.ics');
    } else {
        // Open/Save link in Modern Browsers
        window.open(encodeURI("data:text/calendar;charset=utf8," + calenderData));
    }
  };

  const formatDateDescription = (dateDescription) => {
    // ohne Zeitangabe
    if (!dateDescription.time) {
      return dateDescription.date;
    }
    // mit Zeitangabe
    const splitDate = (dateDescription.date || []).split('-');
    const splitTime = (dateDescription.time || []).split('-');
    // ein Tag
    if (splitDate.length === 1) {
      return `${splitDate[0]} ${splitTime[0]} bis ${splitTime[1]} Uhr`;
    }
    // zwei Tage
    return `${splitDate[0]} (${splitTime[0]} Uhr) bis ${splitDate[1]} (${splitTime[1]} Uhr)`;
  }

  return course ? (

    <div className="course-details">

      <div className="row mt-x-large mb-large">
        <div className="col-lg-12">
          <p className="course-number">{ course.number }</p>
          <h1>{ course.name }</h1>
          <p className="course-description" style={{whiteSpace: "pre-wrap"}}>{ course.description }</p>
        { course.external &&
          <p><b>Weitere Infos:</b> <a href={ course.external }><u>{ course.external }</u></a></p>
        }
        </div>
      </div>
      
      {/* Allgemeine Infos und Kurs Logo */}
      <div className="row">
        <div className="col-lg-9">
          <div className="info-group-container">
            <p className="course-files-headline">Allgemeine Infos</p>

          {
            showDate(course.dates) && 
            <>
            <div className="row info-group">
              <div className="col-lg-3">
                <p><span>Termine</span></p>
              </div>
              <div className="col-lg-9">
                { sortDateDescriptions(course.descriptions || []).map((item, index) => (
                  <p className="termine" key={ index }>{formatDateDescription(item)}: { item.description}{ item.location ? ` - ${item.location}` : '' }</p>
                ))}
                <a onClick={ downloadCalender } className="course-files-headline course-file mt-small cursor-pointer">
                  <i className="fa fa-calendar"></i>
                  { course.descriptions.length > 1 ? 'Kalendereintrag für alle Termine herunterladen' : 'Kalendereintrag herunterladen' }
                </a>
              </div>
            </div>
            </>
          }
          </div>

        {
          showDeadline && showDate(course.dates) && 
          <div className="info-group-container">
            <div className="row info-group">
              <div className="col-lg-3">
                <p><span>Anmeldeschluss</span></p>
              </div>
              <div className="col-lg-9">
                <p>{ course.deadline }{ showOccupancy(course) ? `, ${showOccupancy(course)}` : '' }</p>
              </div>
            </div>
          </div>
        }

          <div className="info-group-container">
            <div className="info-group row">
              <div className="col-lg-3">
                <p><span>Ort</span></p>
              </div>
              <div className="col-lg-9">
                <p>{ course.location }</p>
              </div>
            </div>
            {
              getMappedTags(course.tags, 'regions') ? 
              <div className="info-group row">
                  <div className="col-lg-3">
                    <p><span>Region</span></p>
                  </div>
                  <div className="col-lg-9">
                    <p>{ getMappedTags(course.tags, 'regions') } </p>
                  </div>
              </div> : ''
            }
          </div>
        
          <div className="info-group-container">
            <div className="info-group row">
              <div className="col-lg-3">
                <p><span>Veranstalter</span></p>
              </div>
              <div className="col-lg-9">
                <p>{ course.organizer }</p>
              </div>
            </div>


          {
            course.trainer && course.trainer.name &&
            <div className="info-group row">
              <div className="col-lg-3">
                <p><span>Ansprechpartner/in</span></p>
              </div>
              <div className="col-lg-9">
                <p>{ `${course.trainer.name}${course.trainer.email ? `, ${course.trainer.email}` : ''}${course.trainer.address ? `, ${course.trainer.address}` : ''}${course.trainer.phone ? `, ${course.trainer.phone}` : ''}` }</p>
              </div>
            </div>
          }
        </div>

          <div className="info-group-container">
            {
              getMappedTags(course.tags, 'targets') ?
              <div className="info-group row">
                <div className="col-lg-2">
                  <p><span>Zielgruppe</span></p>
                </div>
                <div className="col-lg-10">
                  <p>{ getMappedTags(course.tags, 'targets') } </p>
                </div>
              </div> : ''
            }
            {
              getMappedTags(course.tags, 'instruments') ?
              <div className="info-group row">
                <div className="col-lg-2">
                  <p><span>Instrument</span></p>
                </div>
                <div className="col-lg-10">
                  <p>{ getMappedTags(course.tags, 'instruments') } </p>
                </div>
              </div> : ''
            }
          </div>
        </div>

        <div className="col-lg-3" style={{ textAlign: 'right' }}>
        { course.logo && course.logo.image &&
          <>
          <img 
            src={ course.logo.image } 
            alt={ course.logo.alt } 
            className="course-logo course-logo-desktop" 
            ref={ courseLogoRef } 
            onLoad={ setCourseLogoHeight } 
            onClick={ () => setShowCourseLogoModal(true) }
            style={{ cursor: 'pointer' }}
          />
          <img 
            src={ course.logo.image } 
            alt={ course.logo.alt } 
            className="course-logo course-logo-mobile mb-medium" 
            ref={ courseLogoRef } 
            onLoad={ setCourseLogoHeight }
          />
          { course.logo.alt &&
            <p className="course-logo-caption" style={{ textAlign: 'center' }}>{ course.logo.alt }</p>
          }
          <Modal show={ showCourseLogoModal } size="lg" onHide={ () => setShowCourseLogoModal(false) }>
            <Modal.Body style={{ textAlign: 'center' }}>
              <img 
                src={ course.logo.image } 
                alt={ course.logo.alt } 
                className="course-logo"
                ref={ courseLogoRef } 
                onLoad={ setCourseLogoHeight } 
              />
            { course.logo.alt &&
              <p className="course-logo-caption">{ course.logo.alt }</p>
            }
            </Modal.Body>
          </Modal>
          </>
        }
        </div>

      </div>
      
    { course.files && Boolean(course.files.length) &&
      <div className="row">
        <div className="col-lg-12 course-files">
          <p className="course-files-headline">Ausschreibungsunterlagen</p>
          {
            (course.files || []).map((file, index) => (
              <p className="course-file" key={index}>
                <i className="fa fa-download"></i>
                <a href={ file.link }>{ file.name }</a>
              </p>
            ))
          }
        </div>
      </div>
    }

    {
      (course.type && (course.type === 'D1' || course.type === 'D2' || course.type === 'D3')) ||
      (course.name.toLowerCase().includes('d1') && course.name.toLowerCase().includes('plus')) ||
      (course.name.toLowerCase().includes('d2') && course.name.toLowerCase().includes('plus')) ?
      <div className="row">
        <div className="col-lg-12">
          <a target="_blank" className="course-files-headline course-file mt-small" href="https://bbmv-online.de/musikerleistungsabzeichen/musikerleistungsabzeichen#c"><i className="fa fa-external-link"></i>Allgemeine Infos zu Musikerleistungsabzeichen (Prüfungsordnung, Nachteilsausgleich und dgl.)</a>
          <a target="_blank" className="course-files-headline course-file mt-small" href="https://bbmv-online.de/musikerleistungsabzeichen/downloads#c"><i className="fa fa-external-link"></i>Downloadbereich (Test-/Lösungsbögen für Theorieprüfung, Selbstwahllisten usw.)</a>
        </div>
      </div> : ''
    }

    </div>

  ) : '';
}

export default CourseInfo;