import React from 'react';
import queryString from 'query-string';
import HttpService from '../../services/http.service';

export class UploadForm extends React.Component {
  httpService;
  token;
  courseNumber;
  union;

  constructor(props) {
    super(props);

    const { token, Number, union } = queryString.parse(this.props.location.search);
    this.token = token;
    this.courseNumber = Number;
    this.union = union;

    if (!this.token || !this.courseNumber || !this.union) {
      return window.location.href = '/';
    }

    this.state = {
      course: null,
      formFields: [],
      saving: false
    };

    this.httpService = new HttpService();

    this.uploadRef1 = React.createRef();
    this.uploadRef2 = React.createRef();
    this.uploadRef3 = React.createRef();
  }

  handleFileChange = (ev) => {
    const target = ev.target;
    const file = ev.target.files[0];
    const nextFormData = { ...this.state.formData, [target.name]: file };
    this.setState({ formData: nextFormData });
  }

  handleSubmit = () => {
    this.setState({ saving: true });

    this.httpService.uploadAttachments(this.token, this.courseNumber, this.union, this.state.formData)
        .then((res) => {
          return window.location.href = '/anmeldung-bestaetigung-unterlagen';
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.length) {
            const message = error.response.data.join(' ');
            alert(message);
          }
          this.setState({ 
            saving: false,
            formData: {}
          });
          this.uploadRef1.current.value = "";
          this.uploadRef2.current.value = "";
          this.uploadRef3.current.value = "";
        });
  }
  
  render() {
    if (!this.token || !this.courseNumber || !this.union) {
      return <div></div>
    }

    return (
        <div className="mt-x-large mb-x-large">

            <div className="row row-find-headline mb-medium">
                <div className="col align-center">
                    <h1>Dateiupload</h1>
                    <p>Hier können ergänzende Unterlagen der Anmeldung beigefügt werden (z.B. Nachweise, SEPA-Lastschrift usw.). Es sind nur maximal 3 Dateien vom Typ PDF, jpg und png möglich. Die maximale Dateigröße beträgt jeweils 2 MB.</p>
                </div>
            </div>

            <div className="form-row row-insertion-form mb-x-large">

                <div className="col-md-12 mt-small">
                    <input type="file"  name="appendix_1" onChange={ this.handleFileChange } ref={ this.uploadRef1 } />
                </div>
                <div className="col-md-12 mt-small">
                    <input type="file" name="appendix_2" onChange={ this.handleFileChange } ref={ this.uploadRef2 } />
                </div>
                <div className="col-md-12 mt-small mb-large">
                    <input type="file" name="appendix_3" onChange={ this.handleFileChange } ref={ this.uploadRef3 } />
                </div>

                <div className="col-md-12 align-right">
                    <button type="button" className="btn submit-button" onClick={ this.handleSubmit } disabled={ this.state.saving }>Absenden</button>
                </div>
            </div>
            
        </div>
    );
  }
}

export default UploadForm;