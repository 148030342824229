import React from 'react';
import HttpService from '../../services/http.service';

const httpService = new HttpService();
const associationMap = {
    'L': 'https://www.nbmb-verband.de/',
    'B': 'https://www.bvv-verband.de/',
    'M': 'https://www.mon-verband.de/',
    'A': 'https://www.asm-verband.de/'
};

export class CourseForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            privacyChecked: false,
            formFields: [],
            formData: {},
            errorsMandatory: []
         };

        this.handleChange = this.handleChange.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handlePrivacy = this.handlePrivacy.bind(this);

        this.uploadRef1 = React.createRef();
        this.uploadRef2 = React.createRef();
        this.uploadRef3 = React.createRef();
    }

    componentDidMount() {
        this.setupFormFields();
    }

    componentDidUpdate(prevProps) {
        if (this.props.course !== prevProps.course) {
            if (this.props.course) {
                this.setupFormFields();
            }
        }
    }

    setupFormFields() {
        const formFields = this.props.course.fields.filter(field => !field.isIntern);
        this.setState({ formFields });
    }

    handleChange(ev) {
        const target = ev.target;
        const nextFormData = { ...this.state.formData, [target.name]: target.value };
        const nextState = { formData: nextFormData };
        if (target.value && this.state.errorsMandatory.includes(target.name)) {
            const nextErrorsMandatory = this.state.errorsMandatory.filter((fieldName) => fieldName !== target.name);
            nextState.errorsMandatory = nextErrorsMandatory;
        }
        this.setState(nextState);
    }

    handleFileChange(ev) {
        const target = ev.target;
        const file = ev.target.files[0];
        const nextFormData = { ...this.state.formData, [target.name]: file };
        this.setState({ formData: nextFormData });
    }

    handleCheckbox(ev) {
        const target = ev.target;
        const nextFormData = { ...this.state.formData, [target.name]: target.checked };
        this.setState({ formData: nextFormData });
    }

    handlePrivacy(ev) {
        const target = ev.target;
        this.setState({ privacyChecked: target.checked });
    }
    
    isNumber = (n) => {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    handleSubmit() {
        const { isValid, errors } = this.validateForm();

        const formData = {
            ...this.state.formData,
        };
        /*
        formData.zipcode = this.state.formData.zipcode + ' ';
        Object.keys(formData).map((key) => {
            if (this.isNumber(formData[key]) && key !== 'zipcode') {
                const value = formData[key].replace(' ', '').trim();
                formData[key] = value.substring(0, 1) + ' ' + value.substring(1, value.length);
            }
        });
        */
        Object.keys(formData).map((key) => {
            if (typeof formData[key] === 'string' || formData[key] instanceof String) {
                let value;
                if (this.isNumber(formData[key] && key !== 'zipcode')) {
                    value = formData[key].replace(' ', '').trim();
                    value = value.substring(0, 1) + ' ' + value.substring(1, value.length);
                } else {
                    value = formData[key].trim();
                }
                formData[key] = value;
            }
        });

        if (isValid) {
            this.setState({ saving: true, errors: [] });

            const requestData = new FormData();
            requestData.append('trainingName', this.props.course.number);
            if (this.props.secret) {
                requestData.append('secret', this.props.secret);
            }
            Object.keys(formData).forEach(key => {
                requestData.append(key, formData[key]);
            });

            httpService.createRegistration(requestData, this.props.course.number.substr(0,1))
                .then((res) => {
                    if (res.data && res.data.length) {
                        const message = res.data.join(' ');
                        alert(message);
                    }
                    localStorage.setItem("confirmation-email", this.state.formData.email);
                    this.setState({ 
                        saving: false,
                        privacyChecked: false,
                        formData: {}
                    });
                    this.uploadRef1.current.value = "";
                    this.uploadRef2.current.value = "";
                    this.uploadRef3.current.value = "";
                    window.location.href = '/anmeldung-erfolgreich';
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.length) {
                        const message = error.response.data.join(' ');
                        alert(message);
                    }
                    return this.setState({ 
                        saving: false,
                        privacyChecked: false
                    });
                });
        }
        else {
            alert(errors.join(' '));
        }
    }

    matchExact(r, str) {
        const match = str.match(r);
        return match && str === match[0];
    }

    validateForm() {
        let isValid = true;
        const errors = [];
        const errorsMandatory = [];
        this.state.formFields.forEach(field => {
            if (field.isMandatory && !this.state.formData[field.name]) {
                errorsMandatory.push(field.name)
                isValid = false;
                if (!errors.includes('Bitte alle Pflichfelder ausfüllen!')) {
                    errors.push('Bitte alle Pflichfelder ausfüllen!');
                }
            }

            if (field.type === 'euro' && this.state.formData[field.name]) {
                const regexEuro = /[0-9]{1,}[,][0-9]{2}/;
                if (!this.matchExact(regexEuro, this.state.formData[field.name])) {
                    isValid = false;
                    errors.push('Bitte Betrag richtig formatieren !');
                }
            }

            if (field.type === 'time' && this.state.formData[field.name]) {
                const regexTime = /[0-9]{1,2}[:][0-9]{2}/;
                if (!this.matchExact(regexTime, this.state.formData[field.name])) {
                    isValid = false;
                    errors.push('Bitte Datum richtig formatieren !');
                }
            }

            if (field.type === 'date' && this.state.formData[field.name]) {
                const regexDate = /[0-9]{1,2}[.][0-9]{1,2}[.][0-9]{4}/;
                if (!this.matchExact(regexDate, this.state.formData[field.name])) {
                    isValid = false;
                    errors.push('Bitte Datum richtig formatieren !');
                }
            }

            if (field.type === 'iban' && this.state.formData[field.name]) {
                const regexIban = /[A-Z]{2}[0-9 ]{20,25}/;
                if (!this.matchExact(regexIban, this.state.formData[field.name])) {
                    isValid = false;
                    errors.push('Bitte Iban richtig formatieren !');
                }
            }

            if ((field.type === 'email' || field.name === 'email') && this.state.formData[field.name]) {
                const regexEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                if (!regexEmail.test(this.state.formData[field.name])) {
                    isValid = false;
                    errors.push('Bitte Email richtig formatieren !');
                }
            }
        });

        // Datenschutz
        if (!this.state.privacyChecked) {
            isValid = false;
            errors.push('Bitte die Datenschutzerklärung akzeptieren!');
        }

        // plz
        /*
        const regexZipcode = /[0-9]{5}/;
        if (this.state.formData.zipcode && !this.matchExact(regexZipcode, this.state.formData.zipcode)) {
            isValid = false;
            errors.push('Bitte die Postleitzahl richtig formatieren!');
        }
        */

        // dob
        const regexDob = /[0-9]{1,2}[.][0-9]{1,2}[.][0-9]{4}/;
        if (this.state.formData.dob && !this.matchExact(regexDob, this.state.formData.dob)) {
            isValid = false;
            errors.push('Bitte das Geburtsdatum im Format tt.mm.jjjj angeben!');
        }

        this.setState({ errorsMandatory });

        return { isValid, errors };
    }

    getPlaceholder(field) {
        const prefix = field.isMandatory ? '*' : '';
        const fieldName = this.checkForMandatoryFieldName(field);
        return (fieldName ? fieldName : (field.description ? field.description : field.name)) + prefix;
    }

    getPlaceholderAndFormat(field) {
        const fieldname = this.getPlaceholder(field);

        if (field.name === 'dob') {
            field.type = 'date';
        }

        switch (field.type) {
            case 'iban':
                return fieldname + ': im Format DE00 0000 0000 0000 0000 00 angeben';

            case 'euro':
                return fieldname + ': im Format 0,00 angeben';

            case 'date':
                return fieldname + ': im Format tt.mm.jjjj angeben. Angabe Geburtsdatum siehe Datenschutzerklärung.';

            case 'time':
                return fieldname + ': im Format hh:mm angeben';

            default:
                return fieldname;
        }
    }

    checkForMandatoryFieldName(field) {
        switch (field.name) {
            case 'firstname':
                return 'Vorname des Teilnehmenden';
            
            case 'lastname':
                return 'Nachname des Teilnehmenden';
            
            case 'street':
                return 'Straße und Hausnummer';
            
            case 'zipcode':
                return 'Postleitzahl';
            
            case 'city':
                return 'Ort';
            
            case 'dob':
                return 'Geburtsdatum';
            
            case 'email':
                return 'Email-Adresse';
            
            case 'sex':
                return 'Geschlecht';
            
            default:
                return '';
        }
    }

    renderFormElement(field, index) {
        switch (field.type) {
            case 'euro':
            case 'date':
            case 'email':
            case 'phone':
            case 'text':
            case 'time':
                return this.renderTextfield(field, index);

            case 'iban':
                return this.renderIban(field, index);

            case 'boolean':
                return this.renderCheckbox(field, index);
            
            case 'dropdown':
                return this.renderDropdown(field, index);
            
            case 'textarea':
                return this.renderTextarea(field, index);

            default:
                return this.renderTextfield(field, index);
        }
    }
    
    renderTextfield(field, index) {
        return (
            <div className="col-md-6" key={ index }>
                <div className="form-group">
                    <input 
                        // type={ field.type }
                        type="text"
                        className={ `form-control ${this.state.errorsMandatory.includes(field.name) ? 'error' : ''}`}
                        name={ field.name }
                        placeholder={ this.getPlaceholder(field) }
                        value={ this.state.formData[field.name] || '' } 
                        onChange={ this.handleChange }
                    />
                    <p className={ 'form-field-title' + (this.state.formData[field.name] ? ' show' : '') }>{ this.getPlaceholderAndFormat(field) }</p>
                </div>
            </div>
        )
    }

    renderIban(field, index) {
        return (
            <div className="col-md-6" key={ index }>
                <div className="form-group">
                    <input 
                        // type={ field.type }
                        type="text"
                        className="form-control" 
                        name={ field.name }
                        placeholder={ this.getPlaceholder(field) }
                        value={ this.state.formData[field.name] || '' } 
                        onChange={ this.handleChange }
                    />
                    <p className={ 'form-field-title' + (this.state.formData[field.name] ? ' show' : '') }>{ this.getPlaceholderAndFormat(field) }</p>
                </div>
            </div>
        )
    }

    renderCheckbox(field, index) {
        return (
            <div className="col-md-6" key={ index }>
                <div className="form-check course-form-checkbox">
                    <input 
                        type="checkbox" 
                        name={ field.name }
                        checked={ this.state.formData[field.name] || false } 
                        onChange={ this.handleCheckbox }
                        id={ field.name }
                    />
                    <label className="form-check-label" htmlFor={ field.name }>
                        <p>{ this.getPlaceholder(field) }</p>
                    </label>
                </div>
            </div>
        )
    }

    renderDropdown(field, index) {
        const dropdownValues = field.dropdownValues ? field.dropdownValues.split(',') : undefined;
        return dropdownValues ? (
            <div className="col-md-6" key={ index }>
                <div className="form-group">
                    <select 
                        className="form-control"
                        name={ field.name }
                        placeholder={ this.getPlaceholder(field) }
                        onChange={ this.handleChange }
                        value={ this.state.formData[field.name] || '' } 
                    >
                        <option value="">{ this.getPlaceholder(field) }</option>
                    {
                    dropdownValues.map(value => (
                        <option value={ value }>{ value }</option>
                    ))
                    }
                    </select>
                    <p className={ 'form-field-title' + (this.state.formData[field.name] ? ' show' : '') }>{ field.description ? field.description : field.name }</p>
                </div>
            </div>
        ) : '';
    }

    renderTextarea(field, index) {
        return (
            <div className="col-md-6" key={ index }>
                <div className="form-group">
                    <textarea 
                        className="form-control" 
                        placeholder={ this.getPlaceholder(field) }
                        name={ field.name }
                        value={ this.state.formData[field.name] || '' } 
                        onChange={ this.handleChange }
                    />
                    <p className={ 'form-field-title' + (this.state.formData[field.name] ? ' show' : '') }>{ field.description ? field.description : field.name }</p>
                </div>
            </div>
        )
    }

    getDatenschutz() {
        let association = this.props.course.number.substr(0, 1);
        if (!['L', 'B', 'M', 'A'].includes(association)) {
            association = 'L';
        }
        return associationMap[association] + 'index.php/form/get_form/form_id/datenschutzerklaerung';
    };

    getAGB() {
        let association = this.props.course.number.substr(0, 1);
        if (!['L', 'B', 'M', 'A'].includes(association)) {
            association = 'L';
        }
        return associationMap[association] + 'index.php/form/get_form/form_id/teilnahmebedingungen_agb';
    };

    render() {
        const datenschutzUrl = this.getDatenschutz();
        const agbUrl = this.getAGB();

        return (
            <div className="mt-x-large mb-x-large">

                <div className="row row-find-headline mb-medium">
                    <div className="col align-center">
                        <h1>Online-Anmeldung für { this.props.course.name }</h1>
                        <p>Pflichtfelder für die Anmeldung sind mit * gekennzeichnet.<br/>Eine Überprüfung der jeweiligen Eingabefelder hinsichtlich des Eingabeformates erfolgt erst nach dem ABSENDEN.</p>
                    </div>
                </div>

                <div className="form-row row-insertion-form mb-x-large">

                    { this.state.formFields.map((field, index) => this.renderFormElement(field, index)) }

                    <div className="col-md-12 upload-info mt-large">
                        <p><b>Dateiupload<br/><br/>Hier können ergänzende Unterlagen der Anmeldung beigefügt werden (z.B. Nachweise, SEPA-Lastschrift usw.). Es sind nur maximal 3 Dateien vom Typ PDF, jpg und png möglich. Die maximale Dateigröße beträgt jeweils 2 MB.</b></p>
                    </div>
                    <div className="col-md-12 mt-small">
                        <input type="file"  name="appendix_1" onChange={ this.handleFileChange } ref={ this.uploadRef1 } accept="image/png,image/jpeg,application/pdf" capture="filesystem"/>
                    </div>
                    <div className="col-md-12 mt-small">
                        <input type="file" name="appendix_2" onChange={ this.handleFileChange } ref={ this.uploadRef2 } accept="image/png,image/jpeg,application/pdf" capture="filesystem"/>
                    </div>
                    <div className="col-md-12 mt-small mb-large">
                        <input type="file" name="appendix_3" onChange={ this.handleFileChange } ref={ this.uploadRef3 } accept="image/png,image/jpeg,application/pdf" capture="filesystem"/>
                    </div>

                    <div className="col-md-12 mt-small">
                        <div className="form-check">
                            <input 
                                type="checkbox" 
                                name="privacyChecked"
                                checked={ this.state.privacyChecked } 
                                onChange={ this.handlePrivacy }
                                id="privacyChecked" 
                            />
                            <label className="form-check-label" htmlFor="privacyChecked">
                                <p>Die <a href={ datenschutzUrl } target="_blank">Datenschutzerklärung</a> sowie die Ausschreibungsunterlagen habe ich gelesen und zur Kenntnis genommen. Mit den <a href={ agbUrl } target="_blank">Teilnahmebedingungen</a> bin ich einverstanden. Die anfallenden Lehrgangs- bzw. Teilnehmergebühren werde ich nach Aufforderung entrichten.</p>
                            </label>
                        </div>
                    </div>

                    <div className="col-md-12 align-right">
                        <button type="button" className="btn submit-button" onClick={ this.handleSubmit } disabled={ this.state.saving }>Absenden</button>
                    </div>
                </div>
                
            </div>
        );
    }
}

export default CourseForm;