import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import './App.css';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Index from './components/Index/Index';
import Insertion from './components/Insertion/Insertion';
import AccountProvider from './components/Account/AccountProvider';
import CourseDetails from './components/CourseDetails/CourseDetails';
import { 
  BookingConfirmed, 
  BookingEmailConfirmed, 
  BookingEmailAlreadyConfirmed,
  BookingErrorAttachments,
  BookingConfirmAttachments
} from './components/LandingPages/LandingPages';
import UploadForm from './components/UploadForm/UploadForm';
import LandingPageCourses from './components/LandingPageCourses/LandingPageCourses';
import LandingPageTargetGroups from './components/LandingPageTargetGroups/LandingPageTargetGroups';
import { Feedback } from './components/Feedback/Feedback';

function Impressum() {
  return (
    <div>
        <h1>Impressum</h1>
    </div>
  );
}

function Datenschutz() {
  return (
    <div>
        <h1>Datenschutz</h1>
    </div>
  );
}

const App = () => {
  const [ bookmarkedCourses, setBookmarkedCourses ] = React.useState([]);

  const bookmarkCourse = (courseToBookmark) => {
    let nextBookmarkedCourses;
    if (!bookmarkedCourses.find(course => course === courseToBookmark.number)) {
      nextBookmarkedCourses = [ ...bookmarkedCourses, courseToBookmark.number ];
    }
    else {
      nextBookmarkedCourses = bookmarkedCourses.filter(course => course !== courseToBookmark.number);
    }
    setBookmarkedCourses(nextBookmarkedCourses);
    localStorage.setItem('KursFinderBookmarkedCourses', JSON.stringify(nextBookmarkedCourses));
  };

  React.useEffect(() => {
    try {
      const bookmarkedCourses = JSON.parse(localStorage.getItem('KursFinderBookmarkedCourses'));
      if (bookmarkedCourses && Array.isArray(bookmarkedCourses)) {
        setBookmarkedCourses(bookmarkedCourses);
      }
    } catch {
      setBookmarkedCourses([]);
    }
  }, []);

  return (
    <Router>
      <ScrollToTop>
        <div className="App container">

          <Header />

          <Route path="/" exact render={ () => <Index bookmarkedCourses={ bookmarkedCourses } onBookmarkCourse={ bookmarkCourse } onDeleteBookmarkedCourses={ () => setBookmarkedCourses([]) } /> } />
          <Route path="/kurs-vorschlagen" component={ Insertion } />
          <Route path="/briefkasten-kummerbox" component={ Feedback } />
          <Route path="/intern" component={ AccountProvider } />
          <Route path="/impressum" component={ Impressum } />
          <Route path="/datenschutz" component={ Datenschutz } />
          <Route path="/kurse/:courseNumber" component={ CourseDetails } />
          <Route path="/landingpage/kurse/:courseNumbers" component={ LandingPageCourses } />
          <Route path="/landingpage/zielgruppen/:association/:filter" component={ LandingPageTargetGroups } />
          <Route path="/anmeldung-erfolgreich" exact component={ BookingConfirmed } />
          <Route path="/anmeldung-bestaetigung" exact component={ BookingEmailConfirmed } />
          <Route path="/anmeldung-bereits-bestaetigt" exact component={ BookingEmailAlreadyConfirmed } />
          <Route path="/anmeldung-fehler-unterlagen" exact component={ BookingErrorAttachments } />
          <Route path="/anmeldung-bestaetigung-unterlagen" exact component={ BookingConfirmAttachments } />
          <Route path="/datei-upload" component={ UploadForm } />          

          <Footer />
          
        </div>
      </ScrollToTop>
    </Router>
  );
}

export default App;
