import React from 'react';
import baystWK from '../../assets/img/bayst-wissenschaft-und-kunst.jpg';
import baystAS from '../../assets/img/bayst-arbeit-und-soziales.jpg';

function Footer() {  
    return (
        <div className="footer">
            <div className="row row-footer mb-x-large">
                <div className="col-md-4">
                    <h3>Nordbayerischer Musikbund e.V.</h3>
                    <p>An der Spielleite 12</p>
                    <p>97294 Unterpleichfeld</p>
                    <p><a href="mailto:info@nbmb.de">info@nbmb.de</a></p>
                    <p><a href="http://www.nbmb.de">www.nbmb.de</a></p>

                    <h3 className="mt-small">Nordbayerische Bläserjugend e.V.</h3>
                    <p>An der Spielleite 12</p>
                    <p>97294 Unterpleichfeld</p>
                    <p><a href="mailto:info@blaeserjugend.de">info@blaeserjugend.de</a></p>
                    <p><a href="http://www.blaeserjugend.de">www.blaeserjugend.de</a></p>
                </div>
                <div className="col-md-4">
                    <h3>Musikbund Ober- und Niederbayern e.V.</h3>
                    <p>Kolpingstraße 46</p>
                    <p>86916 Kaufering</p>
                    <p><a href="mailto:info@mon.bayern">info@mon.bayern</a></p>
                    <p><a href="http://www.mon.bayern">www.mon.bayern</a></p>
                    <br/>
                    <p>Die Maßnahmen werden unterstützt von:</p>
                    <img src={ baystWK } />
                    <img src={ baystAS } />
                </div>
                <div className="col-md-4">
                    <h3>Allgäu-Schwäbischer Musikbund e.V.</h3>
                    <p>Hauptstraße 10</p>
                    <p>86381 Krumbach-Billenhausen</p>
                    <p><a href="mailto:info@asm-online.de">info@asm-online.de</a></p>
                    <p><a href="http://www.asm-online.de">www.asm-online.de</a></p>

                    <h3 className="mt-small">Blasmusikverband Vorspessart e.V.</h3>
                    <p>Kapellenstraße 5</p>
                    <p>63831 Wiesen</p>
                    <p><a href="mailto:info@blasmusikverband-vorspessart.de">info@blasmusikverband-vorspessart.de</a></p>
                    <p><a href="http://www.blasmusikverband-vorspessart.de">www.blasmusikverband-vorspessart.de</a></p>
                </div>
            </div>
            <div className="row row-footer-copy">
                <div className="col align-center">
                    <a href="https://www.nbmb-verband.de/index.php/form/get_form/form_id/impressum" target="_blank">Impressum</a>
                    <a href="https://www.kurs-finder.de/dokumente/Datenschutzhinweise_Kurs-Finder.pdf" target="_blank">Datenschutzhinweise</a><br/>

                    &copy; Nordbayerischer Musikbund e.V. / Nordbayerische Bläserjugend e.V.
                </div>
            </div>
        </div>
    );
}

export default Footer;