import React from 'react';
import moment from 'moment';
import Fuse from 'fuse.js'
import SearchAndFilter from '../SearchAndFilter/SearchAndFilter';
import KurseTable from '../KurseTable/KurseTable';
import Navigation from '../Navigation/Navigation';
import HttpService from '../../services/http.service';
import { isCourseNumber, isOwnderId, isRegistrationPeriodActive } from '../../services/common.helper';

export class Index extends React.Component {
  httpService;
  courses;
  // limit;
  
  constructor(props) {
    super(props);

    this.state = {
      filteredCourses: null,
      courseCount: 0,
      showOnlyBookmarkedCourses: false
    };

    // this.setInitLimit();
    this.httpService = new HttpService();
    this.filterCourses = this.filterCourses.bind(this)
    //this.handleLoadMore = this.handleLoadMore.bind(this);
  }

  /*
  setInitLimit() {
    const params = new URLSearchParams(document.location.search.substring(1));
    const mobile =  (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.innerWidth <= 767) ? true : false;
    const defaultLimit = mobile ? '10' : '25';
    this.limit = params.get("Limit") || defaultLimit;
  }
  */

  componentDidMount() {
    this.httpService.getAllCourses()
      .then(courses => {
        this.courses = courses;
        this.pipeCourses();
      });
  }

  /*
  handleLoadMore() {
    const searchParams = new URLSearchParams(document.location.search.substring(1));
    const nextLimit = parseInt(this.limit) + 10;
    searchParams.set("Limit", nextLimit);
    this.limit = nextLimit;
    const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
    window.history.pushState(null, '', newRelativePathQuery);
    this.pipeCourses()
  }
  */

  handleUrlParamsChange = (key, value/*, paramIsLimit = false*/) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    /*
    if (!paramIsLimit) {
      searchParams.set("Limit", this.limit);
    }
    else {
      this.limit = value;
    }
    */
    const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
    window.history.pushState(null, '', newRelativePathQuery);
    this.pipeCourses();
  }

  pipeCourses() {
    const filteredCourse = this.filterCourses();
    const sortedCoruse = this.sortCourses(filteredCourse);
    this.setState({ 
      courseCount: sortedCoruse.length,
      filteredCourses: sortedCoruse 
    });
  }

  sortCourses(courses) {
    const params = new URLSearchParams(document.location.search.substring(1));
    const sortParam = params.get("Sort");
    switch (sortParam) {
      case 'Relevanz':
        return courses;
      case 'Lehrgangsdatum':
        return this.sortCoursesByDate(courses);
      case 'KursnummerAufsteigend':
        return this.sortCoursesByCourseNumber(courses);
      case 'Zeitpunkt':
        return this.sortCoursesByCourseNumber(courses, true);
      default:
        return this.sortCoursesByDate(courses);
    }
  }

  sortCoursesByDate(courses) {
    const nextCourses = [ ...courses ];
    const sortedCourses = nextCourses.map(course => {
      let date;
      try {
        const dateStr = course.dates[0].split('-')[0];
        date = moment(dateStr, 'DD.MM.YYYY').toDate();
      }
      catch {
        date = moment().add(5, 'y').toDate();
      };

      return {
        ...course,
        date
      };
    });
    sortedCourses.sort((a, b) => {
      return moment(a.date).toDate() - moment(b.date).toDate();
    });
    return sortedCourses;
  }

  sortCoursesByCourseNumber(courses, desc) {
    const nextCourses = [ ...courses ];
    nextCourses.sort((a, b) => {
      if (desc) {
        return b.numberAsInt - a.numberAsInt;
      }
      return a.numberAsInt - b.numberAsInt;
    });
    return nextCourses;
  }

  filterCourses() {
    const params = new URLSearchParams(document.location.search.substring(1));
    const searchParam = params.get("Suche");
    const filterParam = params.get("Filter");
    const associationParam = params.get("Verband");
    const dataFilterParam = params.get("Date");
    const registrationPeriodActiveParam = params.get("RegistrationPeriodActive");
    // const limitParam = params.get("Limit") || this.limit;

    // take all courses if date filter is set!
    let courses = dataFilterParam ? this.courses : this.courses.filter(c => c.future);

    if (filterParam) {
      const filter = filterParam.split('-');
      if (filter.length) {
        courses = courses.filter(course => this.compareTagsAndFilter(course, filter));
      }
    }

    if (dataFilterParam) {
      const dateFilterMom = moment(dataFilterParam, "DD-MM-YYYY");
      courses = courses.filter(course => this.filterByDate(course, dateFilterMom));
    }

    if (searchParam) {
      const keywords = searchParam.split(" ");
      courses = courses.filter(course => keywords.every((keyword) => `${course.number.toLowerCase()}${course.ownerId.toLowerCase()}${course.owner.toLowerCase()}${course.description.toLowerCase()}${course.name.toLowerCase()}${course.keywords.toLowerCase()}${course.ownerId.toLowerCase()}`.includes(keyword.toLowerCase())));
      /*
      if (isCourseNumber(searchParam)) {
        courses = courses.filter(course => course.number.toLowerCase() === searchParam.toLocaleLowerCase());
      } else if (isOwnderId(searchParam)) {
        courses = courses.filter(course => course.ownerId.toLowerCase() === searchParam.toLocaleLowerCase());
      } else {
        const options = {
          includeScore: true,
          ignoreLocation: true,
          threshold: 0.5,
          keys: [
            { name: 'owner', weight: 1 }, 
            { name: 'description', weight: 1 },
            { name: 'keywords', weight: 2 }, 
            { name: 'name', weight: 3 }, 
            { name: 'number', weight: 4 }
          ]
        }
        const fuse = new Fuse(courses, options)
        const result = fuse.search(searchParam);
        courses = result.map((result) => result.item);
      }
        */
    }

    if (associationParam) {
      courses = courses.filter(course => course.number[0] === associationParam);
    }

    if (registrationPeriodActiveParam === null || registrationPeriodActiveParam === '1') {
      courses = courses.filter(course => isRegistrationPeriodActive(course));
    }
    
    // const limitedCourses = courses.slice(0, limitParam);

    return courses;
  }

  searchInCourse(course, searchKeyword) {
    const searchKeywords = searchKeyword.split(" ").filter((s) => s);
    const stringToSearchIn = `${course.name.toLowerCase()} ${course.description.toLowerCase()} ${course.location.toLowerCase()} ${course.number.toLowerCase()} ${course.keywords ? course.keywords.toLowerCase() : ''}`;
    return searchKeywords.some((searchKeyword) => stringToSearchIn.includes(searchKeyword.toLowerCase()));
  }

  compareTagsAndFilter(course, activeFilters) {
    if (!course.tags) {
      return false;
    }

    let show = false;
    activeFilters.forEach(activeFilter => {
      if (course.tags.includes(activeFilter)) {
        show = true;
      }
    });
    return show;
  }

  filterByDate(course, dateFilter) {
    return (course.dates || []).some((date) => {
      const dateRange = date.split('-');
      const start = moment(dateRange[0], "DD.MM.YYYY");
      // single day
      if (dateRange.length === 1) {
        return dateFilter.isSame(start, 'd');
      }
      // date range
      const end = moment(dateRange[1], "DD.MM.YYYY");
      return dateFilter.isSame(start, 'd') || dateFilter.isSame(end, 'd') || dateFilter.isBetween(start, end, 'd');
    });
  }

  render() {
    //const params = new URLSearchParams(document.location.search.substring(1));
    // const limit = parseInt(params.get("Limit")) || this.limit;

    return (
      <div>
        <div className="boxed">
          <Navigation />
          <SearchAndFilter 
            coursesCount={ this.state.courseCount }
            // selectedLimit={ this.limit }
            showOnlyBookmarkedCourses={ this.state.showOnlyBookmarkedCourses }
            onShowOnlyBookmarkedCourses={ (nextShowOnlyBookmarkedCourses) => this.setState({ showOnlyBookmarkedCourses: nextShowOnlyBookmarkedCourses }) }
            onUrlParamsChange={ this.handleUrlParamsChange }
            onDeleteBookmarkedCourses={ this.props.onDeleteBookmarkedCourses }
            onShowAllCourses={ () => this.setState({ showOnlyBookmarkedCourses: false }) }
          />
          <KurseTable
            courses={ this.state.filteredCourses }
            bookmarkedCourses={ this.props.bookmarkedCourses }
            showOnlyBookmarkedCourses={ this.state.showOnlyBookmarkedCourses }
            // showLoadMore={ this.state.courseCount > limit }
            onBookmarkCourse={ this.props.onBookmarkCourse }
            // onDeleteBookmarkedCourses={ this.props.onDeleteBookmarkedCourses }
            // onShowAllCourses={ () => this.setState({ showOnlyBookmarkedCourses: false }) }
            onLoadMore={ this.handleLoadMore }
          />
        </div>
        <div className="row mb-x-large">
          <div className="col">
            <div className="seperator"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Index;