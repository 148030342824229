import React from 'react';
import { Link } from "react-router-dom";

export class NavigationMobile extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showMenu: false };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.setState({ showMenu: !this.state.showMenu });
  }

  render() {
    return (
      <div style={{ width: '100%', padding: '0 15px' }}>
        <div className="align-right">
          <i className="fa navigation-icon fa-bars d-md-none cursor-pointer" onClick={ this.toggleMenu }></i>
        </div>
        { this.state.showMenu ? 
        <div className="mobile-menu">
          <div className="mobile-menu-inner">
            <div className="align-right">
              <i className="fa navigation-icon fa-times cursor-pointer" onClick={ this.toggleMenu }></i>
            </div>
            <div className="mt-large">
              <nav className="row mb-medium">
                <div className="col-md-4 nav-link">
                  <i className="fa navigation-icon fa-angle-right"></i>
                  <Link to="/kurs-vorschlagen"  onClick={ this.toggleMenu }>Kurs/Fortbildung vorschlagen</Link>
                </div>
                <div className="col-md-4 nav-link">
                  <i className="fa navigation-icon fa-angle-right"></i>
                  <Link to="/briefkasten-kummerbox"  onClick={ this.toggleMenu }>Briefkasten / Kummerbox</Link>
                </div>
                <div className="col-md-4 nav-link">
                  <i className="fa navigation-icon fa-angle-right"></i>
                  <Link to="/intern" onClick={ this.toggleMenu }>Interner Bereich für Kursteilnehmer</Link>
                </div>
              </nav>
            </div>
          </div>
        </div> : ''
        }
      </div>
    );
  }
}

export default NavigationMobile;