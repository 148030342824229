import React from 'react';

const getDeaultState = () => ({
    courseNumber: '',
    code: ''
});

export class AccountLogin extends React.Component {
    constructor(props) {
        super(props);

        this.state = { ...getDeaultState() };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(ev) {
        const target = ev.target;
        this.setState({ [target.name]: target.value });
    }

    handleSubmit() {
        this.props.onLogin(this.state);
    }

    render() {
        return (
            <div className="mt-x-large mb-x-large">

                <div className="row row-find-headline mb-medium">
                    <div className="col align-center">
                        <h1>Interner Bereich für Kursteilnehmer</h1>
                    </div>
                </div>

                <div className="form-row row-insertion-form row-account-form mb-x-large">
                    <div className="col-md-4">
                        <div className="form-group">
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder="Kursnummer z.B. (L19851)"
                                name="courseNumber"
                                value={this.state.courseNumber} 
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder="6-stelliger Code" 
                                name="code"
                                value={this.state.code}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <button type="button" className="btn submit-button login-button" onClick={this.handleSubmit}>Einloggen</button>
                    </div>
                </div>
                
            </div>
        );
    }
}

export default AccountLogin;